import React, { useState } from 'react'
import { Accrual, Incoming, Outgo, Credit, Payout } from './formTemplates'
import { useDispatch, useSelector } from 'react-redux'
import { reportOperations } from 'state/entities/operationReport'
import {
	RenderDateTimePicker,
	RenderSelectField,
	RenderCommentField,
	RenderSumField,
	RenderQuantityField,
} from '../recycled'

const required = (value) => (value ? undefined : 'Заполните поле!')

const ModalTemplate = (props) => {
	const { modules } = useSelector((store) => store.session)
	const dispatch = useDispatch()
	const Components = {
		accountingAccrual: Accrual,
		accountingIncoming: Incoming,
		accountingOutgo: Outgo,
		accountingPayout: Payout,
		accountingCredit: Credit,
	}
	const defaultComponent = () => {
		if (modules && modules.accountingIncoming) {
			return Components['accountingIncoming']
		} else {
			for (let index = 0; index < Object.keys(Components).length; index++) {
				const key = Object.keys(Components)[index]
				if (modules[key]) {
					return Components[key]
				}
			}
		}
	}
	let [selectedKey, setState] = useState('')
	const [ChoosenFormComponent, setChoosenFormComponent] = useState(
		defaultComponent
	)
	const onchangeHandler = (e) => {
		const selectedIndex = e.target.options.selectedIndex
		setState(
			() =>
				(selectedKey = e.target.options[selectedIndex].getAttribute('data-key'))
		)
		setChoosenFormComponent(Components[selectedKey])
		dispatch(reportOperations.selectType(e.target.value))
	}

	const { onSubmit, manifest, cancelHandler } = props
	return (
		<ChoosenFormComponent
			manifest={manifest}
			onSubmit={onSubmit}
			cancelHandler={cancelHandler}
			onchangeHandler={onchangeHandler}
			required={required}
			RenderCommentField={RenderCommentField}
			RenderSumField={RenderSumField}
			RenderQuantityField={RenderQuantityField}
			RenderSelectField={RenderSelectField}
			RenderDatePicker={RenderDateTimePicker}
		/>
	)
}
export default ModalTemplate
