const calculatePages = (total, limit, page, operations) => {
	let pages = []
	let tmpPagesButtons = []
	const PAGES_COUNT = Math.ceil(Number(total) / limit)
	tmpPagesButtons.length = 0
	for (let i = 1; i <= PAGES_COUNT; i++) {
		tmpPagesButtons.push({
			num: i,
		})
	}

	const current = page
	if (PAGES_COUNT > 10) {
		if (current < 5) {
			pages = tmpPagesButtons.slice(0, 10)
		} else if (current > PAGES_COUNT - 5) {
			pages = tmpPagesButtons.slice(PAGES_COUNT - 10, PAGES_COUNT)
		} else {
			pages = tmpPagesButtons.slice(current - 5, current + 5)
		}
	} else pages = tmpPagesButtons

	const showedOn = limit * current - limit + 1 // например 100 лим * 2 стр = с 200 записи
	const showedFor = showedOn + operations.length - 1

	const canBack = current > 1 ? true : false //если мы дальше первой то можно назад
	const canFwd = current === PAGES_COUNT ? false : true
	return { pages, showedOn, showedFor, canBack, canFwd }
}
export default calculatePages
