import React from 'react'
import { useState, useEffect } from 'react'
import { Table, Modal, Notification } from '../components/recycled'
import { ModalTemplate } from '../components/operationReport'
import { useDispatch, useSelector } from 'react-redux'
import { modalOperations } from 'state/entities/modal'
import {
	reportOperations,
	reportConstants,
} from 'state/entities/operationReport'
import { getSubdivision } from '../../state/entities/operationReport/utils'
import { REPORT_TYPE } from '../../state/entities/operationReport/constants'

const OperationReport = () => {
	const [radioTypes, setRadioTypes] = useState({
		all: true,
		accountingMoscow: false,
		accountingMoscowPayout: false,
		accountingSPB: false,
		accountingKrasnodar: false,
		accountingNSK: false,
		accountingAkshenov: false,
	})
	const [checkBoxState, setCheckBoxState] = useState(false)
	const operations = useSelector((store) => store.operationReport.report)
	const isLoading = useSelector((store) => store.operationReport.isLoading)
	const count = useSelector((store) => store.operationReport.total)
	const ammount = useSelector((store) => store.operationReport.ammount)
	const tableProps = useSelector((store) => store.operationReport.table)
	const { modules, user } = useSelector((store) => store.session)

	const isModal = useSelector((store) => store.modal.show)
	const modalCategory = useSelector((store) => store.modal.category)
	const isNotify = useSelector((store) => store.notification.show)
	const dispatch = useDispatch()
	const {
		fetchAmmount,
		postReport,
		postAccrualEmployee,
		putDoneAccrualEmployee,
		filterBySubdivision,
		filterByOperationState,
		fetchReportXLS,
		postCreditEmployee,
		deleteCreditEmployee,
		addSessionToOperationReportTableProps,
	} = reportOperations
	useEffect(() => {
		if (modules && user) {
			dispatch(addSessionToOperationReportTableProps(modules, user))
			dispatch(fetchAmmount(modules))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modules, user])
	const rootActionsHandler = async (dataRaw) => {
		if (dataRaw) {
			const data = {
				...dataRaw,
				userId: user.id,
				username: user.fullname,
				subdivision: getSubdivision(modules),
			}
			console.log('operation data', data)
			const { operationId } = await dispatch(postReport(data))
			if (data.operationType === 'Начисление' && operationId) {
				data.operationId = operationId
				await dispatch(postAccrualEmployee(data))
			} else if (data.operationType === 'Выплата' && data.accrualId) {
				dispatch(putDoneAccrualEmployee(data.accrualId))
				if (data.credit && data.creditTotal && data.employeeid) {
					if (data.credit >= data.creditTotal) {
						return dispatch(deleteCreditEmployee(data.employeeid))
					} else {
						const creditEmployeeObj = {
							employeeid: data.employeeid,
							fio: data.fio || null,
							sum: data.credit,
						}
						dispatch(postCreditEmployee(creditEmployeeObj))
					}
				}
			} else if (data.operationType === 'Кредит' && data.employeeid) {
				if (data.sumCredit && data.sum) {
					if (data.sum >= data.sumCredit) {
						return dispatch(deleteCreditEmployee(data.employeeid))
					} else {
						dispatch(
							postAccrualEmployee({
								employeeid: data.employeeid,
								credit: data.sum,
								isDone: true,
							})
						)
					}
				}
				dispatch(postCreditEmployee(data))
			}
		}
	}
	const radioTypesHandler = (e) => {
		let newObj = {}
		newObj[e.target.value] = e.target.checked
		setRadioTypes(newObj)
		dispatch(filterBySubdivision(e.target.value))
	}
	const checkBoxHandler = (e) => {
		const target = e?.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		setCheckBoxState(value)
		dispatch(filterByOperationState(value))
	}
	const addOperationsBtnHandler = () => {
		dispatch(modalOperations.show(true))
		dispatch(modalOperations.setCategory('showForm'))
	}
	return (
		<>
			{isNotify && <Notification />}
			<div className='columns is-vcentered'>
				<div className='column is-7'>
					<h2 className='has-text-weight-light'>Оперативный учет</h2>
					<h3>Касса: {ammount.ammount || 0} руб.</h3>
				</div>
				<div className='column'>
					<button
						className='button is-primary is-outlined is-fullwidth'
						onClick={() =>
							dispatch(fetchReportXLS(tableProps, REPORT_TYPE.default))
						}
						disabled={operations.length === 0}
					>
						<span className='icon'>
							<i className='fas fa-file-excel'></i>
						</span>
						<span>Excel</span>
					</button>
				</div>
				{modules?.accounting && (
					<div className='column'>
						<button
							className='button is-primary is-outlined is-fullwidth'
							onClick={() =>
								dispatch(fetchReportXLS(tableProps, REPORT_TYPE.formal))
							}
							disabled={operations.length === 0}
						>
							<span className='icon'>
								<i className='fas fa-file-excel' />
							</span>
							<span>Excel бухгалтерия</span>
						</button>
					</div>
				)}
				<div className='column'>
					<button
						className='button is-primary is-outlined is-fullwidth'
						onClick={addOperationsBtnHandler}
					>
						Добавить операцию
					</button>
				</div>
			</div>
			<div className='columns'>
				<div className='column is-12'>
					{modules?.accounting && (
						<>
							<div className='subAmmountBox'>
								<div className='subAmountItem'>
									<label className='radio'>
										<input
											type='radio'
											value='all'
											checked={radioTypes.all || false}
											onChange={radioTypesHandler}
										/>
										&nbsp;Все
									</label>
								</div>
								<div className='subAmountItem'>
									<label className='radio'>
										<input
											type='radio'
											value='accountingMoscow'
											checked={radioTypes.accountingMoscow || false}
											onChange={radioTypesHandler}
										/>
										&nbsp;Москва: {ammount.accountingMoscow || 0} руб.
									</label>
								</div>
								<div className='subAmountItem'>
									<label className='radio'>
										<input
											type='radio'
											value='accountingMoscowPayout'
											checked={radioTypes.accountingMoscowPayout || false}
											onChange={radioTypesHandler}
										/>
										&nbsp;Выплаты: {ammount.accountingMoscowPayout || 0} руб.
									</label>
								</div>
								<div className='subAmountItem'>
									<label className='radio'>
										<input
											type='radio'
											value='accountingSPB'
											checked={radioTypes.accountingSPB || false}
											onChange={radioTypesHandler}
										/>
										&nbsp;СПБ: {ammount.accountingSPB || 0} руб.
									</label>
								</div>
								<div className='subAmountItem'>
									<label className='radio'>
										<input
											type='radio'
											value='accountingKrasnodar'
											checked={radioTypes.accountingKrasnodar || false}
											onChange={radioTypesHandler}
										/>
										&nbsp;Краснодар: {ammount.accountingKrasnodar || 0} руб.
									</label>
								</div>
								<div className='subAmountItem'>
									<label className='radio'>
										<input
											type='radio'
											value='accountingNSK'
											checked={radioTypes.accountingNSK || false}
											onChange={radioTypesHandler}
										/>
										&nbsp;Новосибирск: {ammount.accountingNSK || 0} руб.
									</label>
								</div>
								<div className='subAmountItem'>
									<label className='radio'>
										<input
											type='radio'
											value='accountingAkshenov'
											checked={radioTypes.accountingAkshenov || false}
											onChange={radioTypesHandler}
										/>
										&nbsp;Акшонов А.Б.: {ammount.accountingAkshenov || 0} руб.
									</label>
								</div>
							</div>
							<br></br>
							<label className='checkbox'>
								<input
									style={{ marginRight: '5px' }}
									type='checkbox'
									value={checkBoxState}
									onChange={checkBoxHandler}
								></input>
								Показать удаленные операции
							</label>
						</>
					)}
					{isLoading && (
						<progress className='progress is-primary' max='10'>
							1%
						</progress>
					)}

					<Table
						operations={operations}
						count={count}
						tableProps={tableProps}
						dispatch={dispatch}
						modules={modules}
						Modal={Modal}
						manifest={reportConstants}
					/>
					{isModal && modalCategory && modalCategory === 'showForm' && (
						<Modal
							ModalTemplate={ModalTemplate}
							manifest={reportConstants}
							someAction={rootActionsHandler}
						/>
					)}
				</div>
			</div>
		</>
	)
}
export default OperationReport
