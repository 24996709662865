import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')
const formatter = new Intl.NumberFormat('ru')

export const compose = (...fns) => (x) =>
	fns.reduceRight((acc, fn) => fn(acc), x)

export function dataFormatter(...args) {
	const [unFormattedResponce, employees, creditEmployees, user] = args
	const formatted = unFormattedResponce.map((accrual) => {
		const composer = compose(
			dateNormalize,
			addNameField(user, employees),
			addCreditTotal(user, creditEmployees),
			changeKeysForTableHeaders
		)
		const formattedAccrual = composer(accrual)
		return formattedAccrual
	})
	return formatted
}

export function calculateUrl(props) {
	if (!props) return ''
	let url = getUrlWithFilters(props.searchFilter)
	const limit = props.modules?.payrollCanWatchAllAccruals ? 20 : props.limit
	url += `&limit=${limit}`
	if (props.offset) {
		const offset = limit * (props.page - 1)
		url += `&offset=${offset}`
	}
	url += `&orderBy=${props.reverse ? '-' : ''}${props.predicate}`
	return url
}
function getUrlWithFilters(filter) {
	let url = '?'
	let value
	for (const filt in filter) {
		if ((filt === 'startDate' || filt === 'endDate') && filter[filt] !== null) {
			value = JSON.stringify(filter[filt])
		} else {
			value = filter[filt]
		}
		if (value) {
			url += `&${filt}=${value}`
		}
	}
	return url
}
const addCreditTotal = (user, creditEmployees) => (acc) => {
	if (user) {
		const employeWithCredit = creditEmployees.find(
			(employee) => employee.employeeId === acc.employeeId
		)
		acc.creditTotal = employeWithCredit?.sum || null
	}
	return acc
}

export const addNameField = (user, employees) => (acc) => {
	if (!acc) return
	if (!user) {
		const employee = employees.find(
			(employee) => employee.manId.id === acc.employeeId
		)
		if (employee === undefined) return acc
		acc.name =
			`${employee?.manId?.lastName} ${employee?.manId?.firstName}` ||
			'не указано'
	}
	return acc
}

export function dateNormalize(accrual) {
	const date = accrual?.dateAccrual || accrual?.createdAt
	if (!date) return
	const dateAccrual = moment(date).format('DD/MM')
	return { ...accrual, dateAccrual }
}

export const reverseVal = (val) => {
	if (!val) return
	if (val >= 0) return val
	const reverse = val * -1
	return reverse
}

export function parseAndFormat(val) {
	let ammountInt = 0
	if (val === null || val === 0 || typeof val !== 'number') return val
	if (val < 0) ammountInt = reverseVal(val) / 100
	else ammountInt = val / 100
	const formatted = formatter.format(ammountInt)
	return formatted
}

function changeKeysForTableHeaders(accrual) {
	let newAccrualObject
	if (!accrual.typeAccrual || accrual.typeAccrual === 'Зарплата') {
		newAccrualObject = Object.assign({}, accrual)
	} else if (accrual.typeAccrual === 'Аванс') {
		newAccrualObject = {
			id: accrual.id,
			employeeId: accrual.employeeId,
			salaryA: accrual.salary,
			salaryCardA: accrual.salaryCard,
			totalA: accrual.total,
			premium: accrual.premium,
			mulct: accrual.mulct,
			fitness: accrual.fitness,
			lunch: accrual.lunch,
			owertime: accrual.owertime,
			fuel: accrual.fuel,
			amortisation: accrual.amortisation,
			insurance: accrual.insurance,
			comment: accrual.comment,
			fuelCard: accrual.fuelCard,
			rentCar: accrual.rentCar,
			ride: accrual.ride,
			credit: accrual.credit,
			businessTrip: accrual.businessTrip,
			dateAccrual: accrual.dateAccrual,
			isDone: accrual.isDone,
			isDoneForPayroller: accrual.isDoneForPayroller,
			typeAccrual: accrual.typeAccrual,
			createdAt: accrual.createdAt,
			updatedAt: accrual.updatedAt,
		}
	} else if (accrual.typeAccrual === 'Отпуск/БЛ') {
		newAccrualObject = {
			id: accrual.id,
			employeeId: accrual.employeeId,
			salaryO: accrual.salary,
			salaryCardO: accrual.salaryCard,
			totalO: accrual.total,
			premium: accrual.premium,
			mulct: accrual.mulct,
			fitness: accrual.fitness,
			lunch: accrual.lunch,
			owertime: accrual.owertime,
			fuel: accrual.fuel,
			amortisation: accrual.amortisation,
			insurance: accrual.insurance,
			comment: accrual.comment,
			fuelCard: accrual.fuelCard,
			rentCar: accrual.rentCar,
			ride: accrual.ride,
			credit: accrual.credit,
			businessTrip: accrual.businessTrip,
			dateAccrual: accrual.dateAccrual,
			isDone: accrual.isDone,
			isDoneForPayroller: accrual.isDoneForPayroller,
			typeAccrual: accrual.typeAccrual,
			createdAt: accrual.createdAt,
			updatedAt: accrual.updatedAt,
		}
	}
	return newAccrualObject
}

export function totalCashReducer(arr, headers) {
	let returnedObj = {}
	headers.forEach((i) => {
		const key = i.prop
		if (key === 'total' || key === 'totalA' || key === 'totalO') {
			const total = arr.reduce((sum, current) => {
				if (current[key] && !current.isDoneForPayroller)
					return sum + current[key]
				else return sum
			}, 0)
			if (total < 0) returnedObj[key] = reverseVal(total)
			else returnedObj[key] = total
		}
	})
	return returnedObj
}
export function totalReducer(arr, headers) {
	return headers.reduce((acc, i) => {
		const key = i.prop
		if (
			key === 'total' ||
			key === 'salaryA' ||
			key === 'salaryO' ||
			key === 'salaryCard'
		) {
			const total = arr.reduce((sum, current) => {
				if (current[key]) return sum + current[key]
				else return sum
			}, 0)
			if (total < 0) return acc + reverseVal(total)
			else return acc + total
		}
		return acc
	}, 0)
}
