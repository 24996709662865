import * as types from './types'

export const showNotification = (meta) => ({
	type: types.SHOW_NOTIFICATION,
	payload: meta,
})

export const hideNotification = () => ({
	type: types.HIDE_NOTIFICATION,
})
