import * as types from './types'

export const show = (flag) => ({
	type: types.SHOW_MODAL,
	payload: flag,
})
export const setCategory = (category) => ({
	type: types.SET_MODAL_CATEGORY,
	payload: category,
})
