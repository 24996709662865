import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Portal from './Portal'
import { notificationOperations } from 'state/entities/notification'

const Notification = () => {
	const dispatch = useDispatch()
	const meta = useSelector((store) => store.notification)

	const cancelHandler = () => {
		dispatch(notificationOperations.hideNotification())
	}
	return (
		<Portal>
			<div
				className={
					meta.status === 'success'
						? 'notification is-primary is-light'
						: 'notification is-danger is-light'
				}
			>
				<button className='delete' onClick={cancelHandler}></button>
				{meta.message}
			</div>
		</Portal>
	)
}
export default Notification
