import React from 'react'

const RenderQuantityField = ({
	input,
	type,
	isDisabled,
	meta: { touched, error, warning },
}) => (
	<div className='control'>
		<input
			{...input}
			type={type}
			className='input'
			disabled={isDisabled ? true : false}
			placeholder={'0 шт.'}
			onBlur={() => {}}
		/>
		{touched &&
			((error && (
				<article className='message is-danger is-small'>
					<div className='message-body'>{error}</div>
				</article>
			)) ||
				(warning && (
					<article className='message is-danger is-small'>
						<div className='message-body'>{warning}</div>
					</article>
				)))}
	</div>
)
export default RenderQuantityField
