import { OperationReport, AccrualReport } from '../views/pages'
// import { withAuthentication, lazyLoad } from '../views/enhancers'
const routes = [
	{
		path: '/',
		component: OperationReport,
		exact: true,
	},
	{
		path: '/accrualReport',
		component: AccrualReport,
		exact: true,
	},
]

export default routes
