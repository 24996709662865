import React from 'react'
import { useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { green, grey } from '@material-ui/core/colors'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const GreenCheckbox = withStyles({
	root: {
		color: grey[400],
		'&$checked': {
			color: green[600],
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />)

const RowDetails = (props) => {
	const helperObject = {
		createdAt: 'Дата',
		dateAccrual: 'Дата начисления',
		username: 'Пользователь',
		operationType: 'Операция',
		typeAccrual: 'Тип начисления',
		counterparty: 'Контрагент',
		customFields: 'Товары',
		sum: 'Сумма',
		salary: 'Зарплата',
		premium: 'Премия',
		ride: 'Выезды',
		owertime: 'Переработки',
		salaryCard: 'Зарплата на карту',
		businessTrip: 'Командировка на карту',
		credit: 'Возврат кредита',
		mulct: 'Штраф',
		fitness: 'Фитнес',
		lunch: 'Обед',
		fuel: 'ГСМ',
		amortisation: 'Амортизация',
		insurance: 'Страховка',
		fuelCard: 'Топливная карта',
		rentCar: 'Аренда ТС',
		cashless: 'Безнал',
		comment: 'Комментарий',
	}
	const { manifest, cancelHandler } = props
	const details = useSelector((store) => store.operationReport.detailsOperation)

	return (
		<div className='modal is-active'>
			<div className='modal-background'></div>
			<div className='modal-card'>
				<header className='modal-card-head'>
					<p className='modal-card-title'>{manifest.MODALE_TITLE}</p>
					{details?.original?.isDelete && (
						<div style={{ marginLeft: '25px' }}>
							<p className='is-family-secondary has-text-weight-light'>{`Операция удалена ${
								details.original.updatedAt
									? details.original.updatedAt
									: 'ошибка получения времени удаления'
							} пользователем ${
								details.original.modifyrerName
									? details.original.modifyrerName
									: 'ошибка получения пользователя'
							}`}</p>
						</div>
					)}
					<button
						className='delete'
						aria-label='close'
						onClick={cancelHandler}
					></button>
				</header>
				<section className='modal-card-body'>
					<div className='container'>
						{Object.keys(helperObject).map((obj, i) => {
							return (
								<div className='columns is-centered' key={i}>
									{details?.original[obj] && (
										<div className='column is-10'>
											<div className='level is-centered'>
												<div className='level-item '>
													<div className='field lable-custom'>
														<label className='label'>{helperObject[obj]}</label>
													</div>
													{obj === 'customFields' &&
													details?.original.customFields &&
													details?.original.customFields.length > 0 ? (
														<div className='control control-custom'>
															{details?.original.customFields.map((c) => {
																return (
																	<div key={c.name}>
																		{c.name ? `${c.name} / ` : 'не указано'}
																		{c.quantity
																			? `${c.quantity}шт. / `
																			: 'не указано'}
																		{c.sum ? c.sum : 'не указано'}
																	</div>
																)
															})}
														</div>
													) : (
														<div
															className='control control-custom'
															style={{
																maxWidth: '400px',
																overflow: 'auto',
																color:
																	obj === 'sum' &&
																	details.original[obj].indexOf('-') !== -1
																		? 'red'
																		: obj === 'sum'
																		? '#7AEE3C'
																		: '',
															}}
														>
															{details.original[obj] &&
															details.original[obj] === true ? (
																<div>
																	<GreenCheckbox
																		style={{ width: 28, height: 28 }}
																		icon={
																			<CheckBoxOutlineBlankIcon
																				style={{ fontSize: 20 }}
																			/>
																		}
																		checkedIcon={
																			<CheckBoxIcon style={{ fontSize: 20 }} />
																		}
																		checked={true}
																	/>
																</div>
															) : (
																<div>{details.original[obj]}</div>
															)}
														</div>
													)}
												</div>
											</div>
										</div>
									)}
								</div>
							)
						})}
					</div>
				</section>
				<footer className='modal-card-foot'>
					<button className='button is-success' onClick={cancelHandler}>
						{manifest.MODALE_SUBMITE}
					</button>
					<button className='button' onClick={cancelHandler}>
						{manifest.MODALE_CANCEL}
					</button>
				</footer>
			</div>
		</div>
	)
}

export default RowDetails
