import React, { Fragment } from 'react'
import { useEffect, useState } from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { change } from 'redux-form'
import { connect, useDispatch } from 'react-redux'
import {
	RenderSelectField,
	RenderCommentField,
	RenderDateTimePicker,
} from '../recycled'

const AccrualRowForm = (props) => {
	const normalizeAmount = (val) => {
		if (!val) return null
		let norm = val * 100
		return parseFloat(norm.toFixed())
	}
	const formatAmount = (input) => {
		if (input === 0) return 0
		if (!input) return null

		return input / 100
	}
	const [showCustomFields, setshowCustomFields] = useState(false)
	const {
		handleSubmit,
		onSubmit,
		manifest,
		cancelHandler,
		change,
		accrual,
		salary,
		salaryCard,
		businessTrip,
		premium,
		mulct,
		fitness,
		employees,
		lunch,
		insurance,
		fuel,
		amortisation,
		fuelCard,
		ride,
		rentCar,
		owertime,
		credit,
		reset,
		required,
	} = props
	const isFindEmpoloyee = employees.find(
		(e) => e.manId.id === accrual.employeeId
	)
	const currentEmployee =
		(isFindEmpoloyee?.manId &&
			`${isFindEmpoloyee.manId.lastName} ${isFindEmpoloyee.manId.firstName} ${isFindEmpoloyee.manId.patronymic}`) ||
		'Выберите сотрудника'
	const dispatch = useDispatch()
	const handleSelect = (e) => {
		const obj = JSON.parse(e.target.value)
		const { id, firstName, lastName, patronymic } = obj
		const counterparty = `${lastName} ${firstName} ${patronymic}`
		change('accrualRowForm', 'employeeid', id)
		change('accrualRowForm', 'counterparty', counterparty)
	}
	useEffect(() => {
		change('accrualRowForm', 'id', accrual.id)
		change('accrualRowForm', 'employeeId', accrual.employeeId)
		change('accrualRowForm', 'payrollerId', accrual.payrollerId)
		change('accrualRowForm', 'salary', accrual.salary)
		change('accrualRowForm', 'salaryCard', accrual.salaryCard)
		change('accrualRowForm', 'businessTrip', accrual.businessTrip)
		change('accrualRowForm', 'premium', accrual.premium)
		change('accrualRowForm', 'mulct', accrual.mulct)
		change('accrualRowForm', 'fitness', accrual.fitness)
		change('accrualRowForm', 'lunch', accrual.lunch)
		change('accrualRowForm', 'owertime', accrual.owertime)
		change('accrualRowForm', 'fuel', accrual.fuel)
		change('accrualRowForm', 'amortisation', accrual.amortisation)
		change('accrualRowForm', 'fuelCard', accrual.fuelCard)
		change('accrualRowForm', 'ride', accrual.ride)
		change('accrualRowForm', 'rentCar', accrual.rentCar)
		change('accrualRowForm', 'insurance', accrual.insurance)
		change('accrualRowForm', 'credit', accrual.credit)
		change('accrualRowForm', 'dateAccrual', accrual.dateAccrual)
		change('accrualRowForm', 'comment', accrual.comment)
	}, [
		accrual,
		change,
		// creditEmployees,
	])
	useEffect(() => {
		const sumAccrual =
			salary +
			premium +
			ride +
			owertime +
			lunch +
			fuel +
			// TODO(@ipg0): + или - ???
			amortisation -
			salaryCard -
			businessTrip -
			mulct -
			fitness -
			insurance -
			fuelCard -
			rentCar -
			credit
		// const totalAmmount = (sumAccrual + lunch + fuel + amortisation - insurance - fuelCard) * -1
		const totalAmmount = sumAccrual * -1
		change('accrualRowForm', 'sum', sumAccrual)
		change('accrualRowForm', 'total', totalAmmount)
	}, [
		salary,
		premium,
		ride,
		owertime,
		lunch,
		fuel,
		amortisation,
		salaryCard,
		mulct,
		fitness,
		insurance,
		fuelCard,
		rentCar,
		credit,
		change,
		businessTrip,
	])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className='modal is-active'>
				<div className='modal-background'></div>
				<div className='modal-card'>
					<header className='modal-card-head'>
						<p className='modal-card-title'>{manifest.MODALE_TITLE}</p>
						<button
							className='delete'
							aria-label='close'
							onClick={cancelHandler}
						></button>
					</header>
					<section className='modal-card-body'>
						{/* <pre>
								<code>{selectType}</code>
							</pre> */}
						<div className='container'>
							<div className='columns is-centered'>
								<div className='column is-10'>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Сотрудник</label>
												</div>
											</div>
											<Field
												component={RenderSelectField}
												validate={required}
												onChange={handleSelect}
											>
												<option value='' disabled>
													{currentEmployee}
												</option>
												{employees.map((type, i) => {
													return (
														<option
															key={i}
															label={`${type.manId.lastName} ${type.manId.firstName} ${type.manId.patronymic}`}
															value={JSON.stringify(type.manId)}
														>{`${type.manId.lastName} ${type.manId.firstName} ${type.manId.patronymic}`}</option>
													)
												})}
											</Field>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item '>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Тип</label>
												</div>
											</div>
											<Field
												name={'typeAccrual'}
												component={RenderSelectField}
												validate={required}
											>
												<option value='' disabled>
													{accrual.typeAccrual || 'Выберите тип'}
												</option>
												{manifest.ACCRUAL_TYPES.map((type, i) => {
													return <option key={i}>{type}</option>
												})}
											</Field>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item '>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Дата начисления</label>
												</div>
											</div>
											<Field
												name={'dateAccrual'}
												component={RenderDateTimePicker}
											></Field>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Зарплата</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'salary'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Премия</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'premium'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Выезды</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'ride'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Переработки</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'owertime'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Зарплата на карту</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'salaryCard'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Командировка на карту</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'businessTrip'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Удержание штрафа</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'mulct'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Фитнес</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'fitness'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Кредит (возврат)</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													className='input is-fullwidth'
													placeholder={'0,00'}
													name={'credit'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label secondary-messages'>
														Дополнительные поля
													</label>
												</div>
												<div className='level is-centered'>
													{!showCustomFields && (
														<div
															className='icon-box'
															onClick={() => setshowCustomFields(true)}
														>
															<i className='fas fa-chevron-down'></i>
														</div>
													)}
												</div>
												{showCustomFields && (
													<div
														className='icon-box'
														onClick={() => setshowCustomFields(false)}
													>
														<i className='fas fa-chevron-up'></i>
													</div>
												)}
											</div>
										</div>
									</div>
									{showCustomFields && (
										<Fragment>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Обед
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'lunch'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																ГСМ
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'fuel'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Амортизация
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'amortisation'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Страховка
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'insurance'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Топливная карта
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'fuelCard'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
														></Field>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label secondary-labels'>
																Аренда ТС
															</label>
														</div>
													</div>
													<div className='control control-custom'>
														<Field
															className='input is-fullwidth'
															placeholder={'0,00'}
															name={'rentCar'}
															component={'input'}
															type={'number'}
															parse={Number}
															format={formatAmount}
															normalize={normalizeAmount}
														></Field>
													</div>
												</div>
											</div>
										</Fragment>
									)}
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Сумма</label>
												</div>
											</div>
											<div className='control control-custom'>
												<Field
													placeholder={'0,00'}
													className='input is-fullwidth'
													name={'sum'}
													component={'input'}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
													disabled={true}
												></Field>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Комментарий</label>
												</div>
											</div>
											<Field
												name={'comment'}
												component={RenderCommentField}
												validate={required}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<footer className='modal-card-foot'>
						<button className='button is-success' type='submit'>
							{manifest.MODALE_SUBMITE}
						</button>
						<button
							className='button'
							onClick={() => {
								dispatch(reset('accrualRowForm'))
								cancelHandler()
							}}
						>
							{manifest.MODALE_CANCEL}
						</button>
					</footer>
				</div>
			</div>
		</form>
	)
}
const selector = formValueSelector('accrualRowForm')
const mapStateToProps = (state) => {
	const {
		salary,
		salaryCard,
		businessTrip,
		premium,
		mulct,
		fitness,
		employeeid,
		lunch,
		insurance,
		fuel,
		amortisation,
		fuelCard,
		ride,
		rentCar,
		owertime,
		credit,
	} = selector(
		state,
		'salary',
		'salaryCard',
		'businessTrip',
		'premium',
		'mulct',
		'fitness',
		'employeeid',
		'lunch',
		'owertime',
		'credit',
		'insurance',
		'fuel',
		'amortisation',
		'fuelCard',
		'ride',
		'rentCar'
	)
	const { accrual } = state.accrualReport
	const { employees } = state.operationReport
	const { creditEmployees } = state.operationReport
	return {
		accrual,
		salary,
		salaryCard,
		businessTrip,
		premium,
		mulct,
		fitness,
		employees,
		creditEmployees,
		employeeid,
		lunch,
		insurance,
		fuel,
		amortisation,
		fuelCard,
		ride,
		rentCar,
		owertime,
		credit,
	}
}

// Decorate form with dispatchable actions
const mapDispatchToProps = {
	change,
}

// create a redux form, then include the above decorators for the created form to utilize
export default reduxForm({
	form: 'accrualRowForm',
	enableReinitialize: true,
	initialValues: {
		operationType: 'Начисление',
		username: 'Peter',
		counterparty: 'Выберите сотрудника...',
	},
})(connect(mapStateToProps, mapDispatchToProps)(AccrualRowForm))
