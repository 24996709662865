export const FETCH_ACCRUAL_REPORT = 'accrualReport/FETCH_ACCRUAL_REPORT'
export const FETCH_ACCRUAL_REPORT_COMPLETED =
	'accrualReport/FETCH_ACCRUAL_REPORT_COMPLETED'
export const FETCH_ACCRUAL_REPORT_FAILED =
	'accrualReport/FETCH_ACCRUAL_REPORT_FAILED'

export const GET_CURRENT_EDITING_ACCRUAL =
	'accrualReport/GET_CURRENT_EDITING_ACCRUAL'
export const GET_CURRENT_EDITING_ACCRUAL_COMPLETED =
	'accrualReport/GET_CURRENT_EDITING_ACCRUAL_COMPLETED'
export const GET_CURRENT_EDITING_ACCRUAL_FAILED =
	'accrualReport/GET_CURRENT_EDITING_ACCRUAL_FAILED'

export const PUT_ACCRUAL = 'accrualReport/PUT_ACCRUAL'
export const PUT_ACCRUAL_COMPLETED = 'accrualReport/PUT_ACCRUAL_COMPLETED'
export const PUT_ACCRUAL_FAILED = 'accrualReport/PUT_ACCRUAL_FAILED'

export const PUT_DONE_FOR_PAYROLLER_ACCRUAL =
	'accrualReport/PUT_DONE_FOR_PAYROLLER_ACCRUAL'
export const PUT_DONE_FOR_PAYROLLER_ACCRUAL_COMPLETED =
	'accrualReport/PUT_DONE_FOR_PAYROLLER_ACCRUAL_COMPLETED'
export const PUT_DONE_FOR_PAYROLLER_ACCRUAL_FAILED =
	'accrualReport/PUT_DONE_FOR_PAYROLLER_ACCRUAL_FAILED'

export const DELETE_ACCRUAL = 'accrualReport/DELETE_ACCRUAL'
export const DELETE_ACCRUAL_COMPLETED = 'accrualReport/DELETE_ACCRUAL_COMPLETED'
export const DELETE_ACCRUAL_FAILED = 'accrualReport/DELETE_ACCRUAL_FAILED'

export const DELETE_ACCRUAL_BY_OPERATION_ID =
	'accrualReport/DELETE_ACCRUAL_BY_OPERATION_ID'
export const DELETE_ACCRUAL_BY_OPERATION_ID_COMPLETED =
	'accrualReport/DELETE_ACCRUAL_BY_OPERATION_ID_COMPLETED'
export const DELETE_ACCRUAL_BY_OPERATION_ID_FAILED =
	'accrualReport/DELETE_ACCRUAL_BY_OPERATION_ID_FAILED'

export const STEP_FORWARD = 'accrualReport/STEP_FORWARD'
export const STEP_BACK = 'accrualReport/STEP_BACK'
export const STEP_TO_CHOSEN = 'accrualReport/STEP_TO_CHOSEN'

export const FILTER_BY_DATE = 'accrualReport/FILTER_BY_DATE'
export const FILTER_BY_EMPLOYEE = 'accrualReport/FILTER_BY_EMPLOYEE'
export const ADD_SESSION = 'accrualReport/ADD_SESSION'

export const AS_LIKE_USER = 'accrualReport/AS_LIKE_USER'
export const FILTER_HEADERS = 'accrualReport/FILTER_HEADERS'
export const NORMALIZE_DATA = 'accrualReport/NORMALIZE_DATA'
