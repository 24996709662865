import { takeEvery, put, call } from 'redux-saga/effects'
import { hideNotification } from './operations'
import { SHOW_NOTIFICATION } from './types'

export function* notificationWatcher() {
	try {
		yield takeEvery(SHOW_NOTIFICATION, hideWorker)
	} catch (error) {
		console.log(error)
	}
}
function* hideWorker() {
	try {
		yield call(delay, 3000)
		yield put(hideNotification())
	} catch (error) {
		console.log(error)
	}
}
const delay = (time) => new Promise((resolve) => setTimeout(resolve, time))
