import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Icon from '@material-ui/core/Icon'
import { accrualOperations } from 'state/entities/accrualReport'
import AccrualBase from '../components/accrualReport/AccrualBase'

const AccrualReport = () => {
	const { addSessionToAccrualSearchProps } = accrualOperations
	const dispatch = useDispatch()
	const { modules, user } = useSelector((store) => store.session)
	const { employees } = useSelector((store) => store.operationReport)
	const { accruals } = useSelector((store) => store.accrualReport)
	const isLoading = useSelector((store) => store.accrualReport.isLoading)

	const [currentMonth, setCurrentMonth] = useState(
		moment().utcOffset(0, true).format('YYYY-MM')
	)
	const [isFiltered, setFiltered] = useState(false)
	const [defaultSelected, setDefaultSelected] = useState('')
	const [dateRange, setDateRange] = useState([
		moment(currentMonth).utcOffset(0, true).startOf('month').format(),
		moment(currentMonth).utcOffset(0, true).endOf('month').format(),
	])
	const dateHandler = (e) => {
		const newD = e.target.value
		if (!newD) return
		dispatch(
			accrualOperations.filterByDate([
				moment(newD).utcOffset(0, true).startOf('month').format(),
				moment(newD).utcOffset(0, true).endOf('month').format(),
			])
		)
		setCurrentMonth(newD)
		setDateRange([
			moment(newD).utcOffset(0, true).startOf('month').format(),
			moment(newD).utcOffset(0, true).endOf('month').format(),
		])
	}
	const selectHandler = (e) => {
		if (e.target.value) {
			const customField = e.target.value
			const obj = JSON.parse(customField)
			const { id, lastName, firstName, patronymic } = obj
			const counterparty = `${lastName} ${firstName} ${patronymic}`
			setDefaultSelected(counterparty)
			setFiltered(true)
			return dispatch(accrualOperations.filterByEmployee(id))
		}
		setFiltered(false)
		setDefaultSelected('')
		dispatch(accrualOperations.filterByEmployee(null))
	}
	useEffect(() => {
		if (modules && user) {
			dispatch(addSessionToAccrualSearchProps(modules, user, dateRange))
			setDefaultSelected(JSON.stringify(user?.personId))
			setFiltered(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modules, user])
	return (
		<>
			<div className='columns is-centered'>
				<div className='column is-12'>
					<div className='card'>
						<header
							className='card-header'
							style={{
								backgroundColor: '#f3f3f4',
								display: 'flex',
								flexFlow: 'row wrap',
							}}
						>
							{modules?.payrollCanWatchAllAccruals && employees.length > 0 && (
								<div className='card-header-title is-centered'>
									<select
										onChange={selectHandler}
										defaultValue={defaultSelected}
									>
										<option value=''>Все сотрудники</option>
										{employees.map((type, i) => {
											return (
												<option
													key={`slct ${i}`}
													label={`${type.manId.lastName} ${type.manId.firstName} ${type.manId.patronymic}`}
													value={JSON.stringify(type.manId)}
												>
													{defaultSelected}
												</option>
											)
										})}
									</select>
									{isFiltered && (
										<div>
											<Icon
												style={{ fontSize: 15, marginLeft: '5px' }}
												color='action'
												className='fa fa-filter fa-xs'
											></Icon>
										</div>
									)}
								</div>
							)}
							<div className='card-header-title is-centered'>
								<input
									id='accrual-month'
									type='month'
									name='accrual-month'
									value={currentMonth}
									onChange={dateHandler}
								></input>
							</div>
						</header>
						<div className='card-content'>
							<div className='content'>
								{isLoading && (
									<div className='column is-12'>
										<progress className='progress is-primary' max='10'>
											1%
										</progress>
									</div>
								)}
								<AccrualBase accruals={accruals} isLoading={isLoading} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AccrualReport
