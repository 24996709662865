import React from 'react'
import { useState, useEffect } from 'react'
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'

const Credit = (props) => {
	const { modules } = useSelector((store) => store.session)
	const [creditOperationType, setCreditOperationType] = useState('')
	const [currentCreditEmployee, setCurrentCreditEmployee] = useState({})
	const localHandler = (e) => {
		if (e.target.value) {
			setCreditOperationType(e.target.value)
		}
	}

	const normalizeAmount = (val) => {
		if (!val) return null
		let norm
		if (creditOperationType && creditOperationType === 'Возврат') {
			norm = val * 100
		} else {
			norm = val * 100 * -1
		}
		return parseFloat(norm.toFixed())
	}
	const formatAmount = (input) => {
		if (!input) return null
		if (creditOperationType && creditOperationType === 'Возврат') {
			return input / 100
		}
		return (input / 100) * -1
	}

	const {
		handleSubmit,
		onSubmit,
		manifest,
		cancelHandler,
		onchangeHandler,
		change,
		customField,
		employees,
		creditEmployees,
		RenderSelectField,
		RenderCommentField,
		RenderSumField,
		required,
	} = props
	useEffect(() => {
		if (customField) {
			const obj = JSON.parse(customField)
			const { id, firstName, lastName, patronymic } = obj
			if (firstName && lastName) {
				const counterparty = `${lastName} ${firstName} ${patronymic}`
				change('operationReportFormCredit', 'employeeid', id)
				change('operationReportFormCredit', 'counterparty', counterparty)
				change('operationReportFormCredit', 'fio', counterparty)
			} else {
				setCurrentCreditEmployee(obj)
				change('operationReportFormCredit', 'employeeid', obj.employeeId)
				change('operationReportFormCredit', 'counterparty', obj.fio)
				change('operationReportFormCredit', 'sumCredit', obj.sum * -1)
			}
		}
	}, [customField, change])
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className='modal is-active'>
				<div className='modal-background'></div>
				<div className='modal-card'>
					<header className='modal-card-head'>
						<p className='modal-card-title'>{manifest.MODALE_TITLE}</p>
						<button
							className='delete'
							aria-label='close'
							onClick={cancelHandler}
						></button>
					</header>
					<section className='modal-card-body'>
						{/* <pre>
							<code>{selectType}</code>
						</pre> */}
						<div className='container'>
							<div className='columns is-centered'>
								<div className='column is-10'>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Операция</label>
												</div>
											</div>
											<div className='control control-custom'>
												<div className='select is-fullwidth'>
													<Field
														name={'operationType'}
														component={'select'}
														// onChange={onchangeHandler}
														onChange={(e) => {
															change(
																'operationReportFormCredit',
																'operationType',
																e.target.value
															)
															onchangeHandler(e)
														}}
													>
														{manifest.OPERATION_TYPES.filter(
															(i) => modules[i.key]
														).map((type, i) => {
															return (
																<option key={i} data-key={type.key}>
																	{type.value}
																</option>
															)
														})}
													</Field>
												</div>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Тип операции</label>
												</div>
											</div>
											<Field
												name={'creditOperationType'}
												component={RenderSelectField}
												validate={required}
												onChange={localHandler}
											>
												<option value='' disabled>
													Выберите тип...
												</option>
												{manifest.CREDIT_OPERATION_TYPES.map((type, i) => {
													return <option key={i}>{type}</option>
												})}
											</Field>
										</div>
									</div>
									{creditOperationType && creditOperationType === 'Выдача' && (
										<>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-centered'>
														<div className='field-label is-normal'>
															<label className='label'></label>
														</div>
														<div
															className='field-body'
															style={{ marginLeft: '60px' }}
														>
															<div className='field'>
																<label className='checkbox'>
																	<Field
																		style={{
																			marginLeft: '10px',
																			marginRight: '5px',
																		}}
																		name={'cashless'}
																		component={'input'}
																		type={'checkbox'}
																	></Field>
																	Безнал
																</label>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className='level is-centered'>
												<div className='level-item'>
													<div className='field is-horizontal lable-custom'>
														<div className='field-label is-normal'>
															<label className='label'>Сотрудники</label>
														</div>
													</div>
													<Field
														name={'customField'}
														component={RenderSelectField}
														validate={required}
													>
														<option value='' disabled>
															Выберите сотрудника...
														</option>
														{employees.map((type, i) => {
															return (
																<option
																	key={i}
																	label={`${type.manId.lastName} ${type.manId.firstName} ${type.manId.patronymic}`}
																	value={JSON.stringify(type.manId)}
																>{`${type.manId.lastName} ${type.manId.firstName} ${type.manId.patronymic}`}</option>
															)
														})}
													</Field>
												</div>
											</div>
										</>
									)}
									{creditOperationType && creditOperationType === 'Возврат' && (
										<div className='level is-centered'>
											<div className='level-item'>
												<div className='field is-horizontal lable-custom'>
													<div className='field-label is-normal'>
														<label className='label'>Сотрудники</label>
													</div>
												</div>
												<Field
													name={'customField'}
													component={RenderSelectField}
													validate={required}
												>
													<option value='' disabled>
														Выберите сотрудника...
													</option>
													{creditEmployees.map((type, i) => {
														return (
															<option
																key={i}
																label={type.fio}
																value={JSON.stringify(type)}
															>
																{type.fio}
															</option>
														)
													})}
												</Field>
											</div>
										</div>
									)}
									{currentCreditEmployee && currentCreditEmployee.sum && (
										<div className='level is-centered'>
											<div className='level-item'>
												<div className='field is-horizontal lable-custom'>
													<div className='field-label is-normal'>
														<label className='label'>Сумма Задолжености</label>
													</div>
												</div>
												<div className='control control-custom'>
													<Field
														className='input is-fullwidth'
														name={'sumCredit'}
														component={'input'}
														type={'number'}
														parse={Number}
														format={formatAmount}
														normalize={normalizeAmount}
														disabled={true}
													></Field>
												</div>
											</div>
										</div>
									)}
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Сумма</label>
												</div>
											</div>
											<Field
												name={'sum'}
												component={RenderSumField}
												type={'number'}
												parse={Number}
												format={formatAmount}
												normalize={normalizeAmount}
												validate={required}
											></Field>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Комментарий</label>
												</div>
											</div>
											<Field
												type={'text'}
												name={'comment'}
												component={RenderCommentField}
												validate={required}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<footer className='modal-card-foot'>
						<button className='button is-success' type='submit'>
							{manifest.MODALE_SUBMITE}
						</button>
						<button className='button' onClick={cancelHandler}>
							{manifest.MODALE_CANCEL}
						</button>
					</footer>
				</div>
			</div>
		</form>
	)
}
const selector = formValueSelector('operationReportFormCredit')
const mapStateToProps = (state) => {
	const { employeeid, customField } = selector(
		state,
		'employeeid',
		'customField'
	)
	const { employees } = state.operationReport
	const { creditEmployees } = state.operationReport
	return {
		employees,
		creditEmployees,
		employeeid,
		customField,
	}
}

// Decorate form with dispatchable actions
const mapDispatchToProps = {
	change,
}
export default reduxForm({
	// a unique name for the form
	form: 'operationReportFormCredit',
	initialValues: {
		operationType: 'Кредит',
		username: 'Peter',
		counterparty: '',
		employeeid: null,
		comment: '',
		customField: '',
		fio: '',
	},
})(connect(mapStateToProps, mapDispatchToProps)(Credit))
