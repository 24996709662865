import React from 'react'
import { useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { useSelector } from 'react-redux'

const Outgo = (props) => {
	const { modules } = useSelector((store) => store.session)
	const { subdivisions } = useSelector((store) => store.operationReport)

	const normalizeAmount = (val) => {
		if (!val) return null
		let norm = val * 100 * -1
		return parseFloat(norm.toFixed())
	}
	const formatAmount = (input) => {
		if (!input) return null

		return (input / 100) * -1
	}

	const {
		handleSubmit,
		onSubmit,
		manifest,
		cancelHandler,
		onchangeHandler,
		change,
		RenderSelectField,
		RenderCommentField,
		RenderSumField,
		required,
	} = props
	const [arrOfTypes, setarrOfTypes] = useState([])

	const localHandler = (e) => {
		if (e.target.value) {
			if (e.target.value === 'Основные') {
				setarrOfTypes(manifest.COUNTERPARTY_OUTGO_BASE_TYPES)
			} else if (e.target.value === 'Услуги') {
				setarrOfTypes(manifest.COUNTERPARTY_OUTGO_SERVICES_TYPES)
			} else if (e.target.value === 'Внутренние перемещения') {
				setarrOfTypes(subdivisions)
			}
		}
	}
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className='modal is-active'>
				<div className='modal-background'></div>
				<div className='modal-card'>
					<header className='modal-card-head'>
						<p className='modal-card-title'>{manifest.MODALE_TITLE}</p>
						<button
							className='delete'
							aria-label='close'
							onClick={cancelHandler}
						></button>
					</header>
					<section className='modal-card-body'>
						{/* <pre>
							<code>{selectType}</code>
						</pre> */}
						<div className='container'>
							<div className='columns is-centered'>
								<div className='column is-10'>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Операция</label>
												</div>
											</div>
											<div className='control control-custom'>
												<div className='select is-fullwidth'>
													<Field
														name={'operationType'}
														component={'select'}
														// onChange={onchangeHandler}
														onChange={(e) => {
															change(
																'operationReportFormOutgo',
																'operationType',
																e.target.value
															)
															onchangeHandler(e)
														}}
													>
														{manifest.OPERATION_TYPES.filter(
															(i) => modules[i.key]
														).map((type, i) => {
															return (
																<option key={i} data-key={type.key}>
																	{type.value}
																</option>
															)
														})}
													</Field>
												</div>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Группа Контрагента</label>
												</div>
											</div>
											<Field
												name={'counterpartyGroup'}
												component={RenderSelectField}
												validate={required}
												onChange={localHandler}
											>
												<option value='' disabled selected>
													Выберите группу...
												</option>
												{manifest.COUNTERPARTY_OUTGO_GROUPS.map((type, i) => {
													return <option key={i}>{type}</option>
												})}
											</Field>
										</div>
									</div>
									{arrOfTypes.length > 0 && (
										<div className='level is-centered'>
											<div className='level-item'>
												<div className='field is-horizontal lable-custom'>
													<div className='field-label is-normal'>
														<label className='label'>Контрагент</label>
													</div>
												</div>
												<Field
													name={'counterparty'}
													component={RenderSelectField}
													validate={required}
												>
													<option value='' disabled>
														Выберите Контрагента...
													</option>
													{arrOfTypes.map((type, i) => {
														return (
															<option value={type} key={i}>
																{type}
															</option>
														)
													})}
												</Field>
											</div>
										</div>
									)}
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Сумма</label>
												</div>
											</div>
											<Field
												name={'sum'}
												component={RenderSumField}
												type={'number'}
												parse={Number}
												format={formatAmount}
												normalize={normalizeAmount}
												validate={required}
											></Field>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Комментарий</label>
												</div>
											</div>
											<Field
												type={'text'}
												name={'comment'}
												component={RenderCommentField}
												validate={required}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<footer className='modal-card-foot'>
						<button className='button is-success' type='submit'>
							{manifest.MODALE_SUBMITE}
						</button>
						<button className='button' onClick={cancelHandler}>
							{manifest.MODALE_CANCEL}
						</button>
					</footer>
				</div>
			</div>
		</form>
	)
}

export default reduxForm({
	// a unique name for the form
	form: 'operationReportFormOutgo',
	initialValues: {
		operationType: 'Расход',
		username: 'Peter',
		comment: '',
	},
})(Outgo)
