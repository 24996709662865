import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { authOperations } from 'state/entities/auth'
import routes from './routes'

function App() {
	const dispatch = useDispatch()
	const { fetchSession } = authOperations
	useEffect(() => {
		dispatch(fetchSession())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	// для Андрея передаем userId в логи nginx
	const { user } = useSelector((store) => store.session)
	if (user) window.xRequestTypeId = user?.id
	return (
		<div className='columns is-fullheight'>
			<div className='column is-12'>
				{routes.map((route) => (
					<Route key={route.path} {...route} />
				))}
			</div>
		</div>
	)
}

export default App
