import React from 'react'
import { useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { green, grey } from '@material-ui/core/colors'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Icon from '@material-ui/core/Icon'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { accrualOperations } from 'state/entities/accrualReport'
import * as utils from 'state/entities/accrualReport/utils'

const GreenCheckbox = withStyles({
	root: {
		color: grey[400],
		'&$checked': {
			color: green[600],
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />)

const AccrualRow = ({
	asUser,
	accrual,
	i,
	headers,
	startEditing,
	deleteAccrualHandler,
	modules,
}) => {
	const dispatch = useDispatch()
	const localDeleteHanler = (accrual) => {
		const { id } = accrual
		if (id) deleteAccrualHandler(id)
	}
	const checkBoxHandleChange = (event) => {
		const isDone = event.target.checked
		dispatch(accrualOperations.putDoneForPayroller(accrual.id, isDone))
	}
	return (
		<TableRow className='accrualRow' key={`tr-${i}`}>
			{accrual &&
				headers &&
				headers.length > 0 &&
				headers.map((head, k) => (
					<>
						{head?.prop === 'isDoneForPayroller' ? (
							<TableCell
								style={{ padding: '1px', textAlign: 'center' }}
								key={`trc-${k}`}
							>
								<div style={{ cursor: 'pointer' }}>
									<GreenCheckbox
										style={{ width: 28, height: 28 }}
										icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
										checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
										checked={accrual.isDoneForPayroller}
										onChange={checkBoxHandleChange}
									/>
								</div>
							</TableCell>
						) : (
							<TableCell
								key={`trc-${k}`}
								style={{
									backgroundColor:
										head.prop === 'total' ||
										head.prop === 'totalO' ||
										head.prop === 'totalA'
											? '#f3f3f4'
											: null,
									fontSize: '10px',
									textAlign: 'center',
									padding: '1px',
								}}
							>
								{utils.parseAndFormat(accrual[head.prop]) || '0,00'}
							</TableCell>
						)}
					</>
				))}
			{modules?.payrollCanWatchAllAccruals && !asUser && (
				<>
					<TableCell style={{ padding: '1px', textAlign: 'center' }}>
						<div
							style={{ cursor: 'pointer' }}
							onClick={() => {
								startEditing(accrual)
							}}
						>
							<Icon
								style={{ fontSize: 15 }}
								color='action'
								className='fa fa-edit fa-xs'
							></Icon>
						</div>
					</TableCell>
					<TableCell
						style={{
							borderRight: '1px solid black',
							padding: '1px',
							textAlign: 'center',
						}}
					>
						<div
							style={{ cursor: 'pointer' }}
							onClick={() => localDeleteHanler(accrual)}
						>
							<Icon
								style={{ fontSize: 15 }}
								color='action'
								className='fa fa-trash fa-xs'
							></Icon>
						</div>
					</TableCell>
				</>
			)}
		</TableRow>
	)
}

export default AccrualRow
