import { all, fork } from 'redux-saga/effects'
import { reportSagas, reportConstants } from './operationReport'
import { notificationSagas } from './notification'
import { accrualSagas } from './accrualReport'

const rootSaga = function* () {
	yield all(
		[
			...Object.values(reportSagas),
			...Object.values(notificationSagas),
			...Object.values(accrualSagas),
		].map(fork)
	)
}
export { rootSaga, reportConstants }
export { default as operationReport } from './operationReport'
export { default as accrualReport } from './accrualReport'
export { default as modal } from './modal'
export { default as notification } from './notification'
export { default as form } from './reactForm'
export { default as session } from './auth'
