import React from 'react'
import { useDispatch } from 'react-redux'
import Portal from './Portal'
import { modalOperations } from 'state/entities/modal'

const Modal = ({ ModalTemplate, manifest, someAction }) => {
	const dispatch = useDispatch()
	const customHandleSubmit = (formData) => {
		someAction(formData)
		dispatch(modalOperations.show(false))
	}

	const cancelHandler = () => {
		dispatch(modalOperations.show(false))
	}
	return (
		<Portal>
			<ModalTemplate
				manifest={manifest}
				cancelHandler={cancelHandler}
				onSubmit={customHandleSubmit}
			/>
		</Portal>
	)
}
export default Modal
