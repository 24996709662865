import { takeEvery, put, select } from 'redux-saga/effects'
import { fetchAccrualReport, filterHeadres, normalizeData } from './operations'
import * as types from './types'
import { searchProps, accruals } from './selectors'
import { modules, user } from '../auth/selectors'
import { employees, creditEmployees } from '../operationReport/selectors'
import { showNotification } from '../notification/operations'
import {
	fetchEmployees,
	fetchSubdivisions,
	fetchCreditEmployees,
} from '../operationReport/operations'

export function* accrualWatcher() {
	try {
		yield takeEvery(types.ADD_SESSION, searchTriggerWorker)
		yield takeEvery(types.FILTER_BY_DATE, searchTriggerWorker)
		yield takeEvery(types.FILTER_BY_EMPLOYEE, searchTriggerWorker)
		yield takeEvery(types.STEP_FORWARD, searchTriggerWorker)
		yield takeEvery(types.STEP_BACK, searchTriggerWorker)
		yield takeEvery(types.STEP_TO_CHOSEN, searchTriggerWorker)
		yield takeEvery(types.AS_LIKE_USER, searchTriggerWorker)
		yield takeEvery(types.FETCH_ACCRUAL_REPORT_COMPLETED, afterFethWorker)
		yield takeEvery(types.NORMALIZE_DATA, afterNormalizeWorker)
		yield takeEvery(types.FETCH_ACCRUAL_REPORT_FAILED, notificationWorker, {
			show: true,
			status: 'error',
			message: 'Ошибка получения данных с сервера!',
		})

		yield takeEvery(
			types.PUT_DONE_FOR_PAYROLLER_ACCRUAL_COMPLETED,
			searchTriggerWorker
		)
		yield takeEvery(
			types.PUT_DONE_FOR_PAYROLLER_ACCRUAL_FAILED,
			notificationWorker,
			{
				show: true,
				status: 'error',
				message: 'Ошибка при отправке данных на сервер!',
			}
		)

		yield takeEvery(types.PUT_ACCRUAL_COMPLETED, searchTriggerWorker)
		yield takeEvery(types.PUT_ACCRUAL_COMPLETED, notificationWorker, {
			show: true,
			status: 'success',
			message: 'Данные успешно обновлены!',
		})
		yield takeEvery(types.PUT_ACCRUAL_FAILED, notificationWorker, {
			show: true,
			status: 'error',
			message: 'Ошибка при отправке данных на сервер!',
		})

		yield takeEvery(types.DELETE_ACCRUAL_COMPLETED, searchTriggerWorker)
		yield takeEvery(types.DELETE_ACCRUAL_COMPLETED, notificationWorker, {
			show: true,
			status: 'success',
			message: 'Данные успешно удалены!',
		})
		yield takeEvery(types.DELETE_ACCRUAL_FAILED, notificationWorker, {
			show: true,
			status: 'error',
			message: 'Ошибка удаления записи!',
		})
	} catch (error) {
		console.log(error)
	}
}

function* searchTriggerWorker() {
	try {
		const props = yield select(searchProps)
		if (
			props.modules?.payrollCanWatchAllAccruals ||
			props.modules?.payrollPayroller
		) {
			yield put(fetchEmployees())
			yield put(fetchSubdivisions())
		}
		yield put(fetchCreditEmployees())
		yield put(fetchAccrualReport(props))
	} catch (error) {
		console.log(error)
	}
}
function* afterFethWorker() {
	try {
		const roles = yield select(modules)
		const people = yield select(user)
		const accs = yield select(accruals)
		const empls = yield select(employees)
		const emplsWthCrdts = yield select(creditEmployees)
		yield put(normalizeData(accs, roles, people, empls, emplsWthCrdts))
	} catch (error) {
		console.log(error)
	}
}
function* afterNormalizeWorker() {
	try {
		const roles = yield select(modules)
		const people = yield select(user)
		const accs = yield select(accruals)
		yield put(filterHeadres(accs, roles, people))
	} catch (error) {
		console.log(error)
	}
}
function* notificationWorker(meta) {
	try {
		yield put(showNotification(meta))
	} catch (error) {
		console.log(error)
	}
}
