import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accrualConstants } from 'state/entities/accrualReport'
import { modalOperations } from 'state/entities/modal'
import { accrualOperations } from 'state/entities/accrualReport'
import { Modal, Notification } from '../recycled'
import AccrualTable from './AccrualTable'
import AccrualRowForm from './AccrualRowForm'

const AccrualBase = ({ accruals, isLoading }) => {
	const columns = accrualConstants.columnsForAccrualTable
	const isNotify = useSelector((store) => store.notification.show)
	const isModal = useSelector((store) => store.modal.show)
	const modalCategory = useSelector((store) => store.modal.category)
	const filterProps = useSelector((store) => store.accrualReport.filter)
	const accrual = useSelector((store) => store.accrualReport.accrual)
	const dispatch = useDispatch()

	const startEditing = (i) => {
		if (i) {
			const found = accruals.find((accrual) => accrual.id === i.id)
			if (found) dispatch(accrualOperations.getCurrentEditingAccrual(found.id))
		}
		if (accrual) {
			dispatch(modalOperations.show(true))
			dispatch(modalOperations.setCategory('showEditAccrualForm'))
		}
	}

	const rootActionsHandler = (data) => {
		if (data && data.id) dispatch(accrualOperations.putAccrual(data.id, data))
	}
	const deleteAccrualHandler = (id) => {
		if (id) dispatch(accrualOperations.deleteAccrual(id))
	}

	return (
		<>
			{isNotify && <Notification />}
			<AccrualTable
				startEditing={startEditing}
				deleteAccrualHandler={deleteAccrualHandler}
				accruals={accruals}
				columns={columns}
				filterProps={filterProps}
				dispatch={dispatch}
				isLoading={isLoading}
			/>
			{isModal && modalCategory && modalCategory === 'showEditAccrualForm' && (
				<Modal
					ModalTemplate={AccrualRowForm}
					manifest={accrualConstants}
					someAction={rootActionsHandler}
				/>
			)}
		</>
	)
}

export default AccrualBase
