import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import AccrualRow from './AccrualRow'
import * as utils from 'state/entities/accrualReport/utils'
import { accrualOperations } from 'state/entities/accrualReport'
import moment from 'moment'

const useStyle = makeStyles({
	root: {
		Table: {
			size: 'small',
		},
	},
})

const AccrualTable = ({
	accruals,
	startEditing,
	deleteAccrualHandler,
	dispatch,
	filterProps,
	isLoading,
}) => {
	let totalCash = undefined
	let total = undefined
	// state
	const currentMonth = moment().utcOffset(0, true).format('YYYY-MM')
	const dateRange = [
		moment(currentMonth).utcOffset(0, true).startOf('month').format(),
		moment(currentMonth).utcOffset(0, true).endOf('month').format(),
	]
	const [asUser, asUserSet] = useState(false)
	// selectors
	const { modules, user } = useSelector((store) => store.session)
	const headers = useSelector((store) => store.accrualReport.tableHeaders)
	let ifOnlyOneCredit, ifCreditTotal
	ifOnlyOneCredit = accruals && accruals.find((a) => a.credit)
	// if admin or payroller
	if (
		!asUser &&
		(modules?.payrollCanWatchAllAccruals || modules?.payrollPayroller)
	) {
		if (modules.payrollPayroller && headers && !asUser) {
			totalCash = utils.totalCashReducer(accruals, headers)
		}
		// else user its all we need
	} else {
		ifCreditTotal = accruals && accruals.find((a) => a.creditTotal)
		total = utils.totalReducer(accruals, headers)
	}
	// handlers
	const checkBoxHandler = (e) => {
		const target = e?.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		asUserSet(value)
	}

	// if user or asUser
	const firstUpdate = useRef(true)
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false
			return
		}
		dispatch(
			accrualOperations.addSessionToAccrualSearchProps(
				modules,
				user,
				dateRange,
				asUser
			)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [asUser])
	const classes = useStyle()
	return (
		<>
			{modules?.payrollCanWatchAllAccruals && !asUser && (
				<nav
					className='pagination is-centered is-small'
					role='navigation'
					aria-label='pagination'
				>
					<button
						className='button pagination-previous is-light'
						disabled={!filterProps.canBack}
						onClick={() => dispatch(accrualOperations.stepBack())}
					>
						Назад
					</button>
					<button
						className='button pagination-next is-light'
						disabled={!filterProps.canFwd}
						onClick={() => dispatch(accrualOperations.stepForward())}
					>
						Вперед
					</button>
					<ul className='pagination-list'>
						{filterProps.pages.map((p, i) => {
							return (
								<button
									key={i}
									value={p.num}
									className={
										p.num === filterProps.page
											? 'button is-light is-small'
											: 'button is-white is-small'
									}
									onClick={() =>
										dispatch(accrualOperations.stepToChosen(p.num))
									}
								>
									{p.num}
								</button>
							)
						})}
					</ul>
				</nav>
			)}
			<div style={{ overflow: 'auto' }}>
				{(modules?.payrollCanWatchAllAccruals || modules?.payrollPayroller) && (
					<label className='checkbox'>
						<input
							style={{ marginRight: '5px' }}
							type='checkbox'
							value={asUser}
							onChange={checkBoxHandler}
						></input>
						Показать мои начисления
					</label>
				)}
				<div>
					{accruals && accruals.length > 0 && (
						<Table
							className={classes.root}
							style={{
								border: '1px solid black',
								width: '100%',
								tableLayout: 'auto',
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell
										style={{
											textAlign: 'center',
											border: '1px solid black',
											fontSize: '1px',
										}}
									/>
									{/* --/ ФИО & Checkbox/-- */}
									{!asUser &&
										(modules?.payrollCanWatchAllAccruals ||
											modules?.payrollPayroller) && (
											<>
												<TableCell
													style={{
														textAlign: 'center',
														border: '1px solid black',
														fontSize: '1px',
													}}
												/>
												<TableCell
													style={{
														textAlign: 'center',
														border: '1px solid black',
														fontSize: '1px',
													}}
												/>
											</>
										)}
									<TableCell
										style={{
											textAlign: 'center',
											border: '1px solid black',
										}}
										colSpan={3}
									>
										Аванс
									</TableCell>
									<TableCell
										style={{ textAlign: 'center', border: '1px solid black' }}
										colSpan={3}
									>
										Отпуск/БЛ
									</TableCell>
									{ifOnlyOneCredit && ifCreditTotal ? (
										<TableCell
											style={{ textAlign: 'center', border: '1px solid black' }}
											colSpan={16}
										>
											Зарплата
										</TableCell>
									) : (
										<TableCell
											style={{ textAlign: 'center', border: '1px solid black' }}
											colSpan={ifOnlyOneCredit ? 15 : ifCreditTotal ? 15 : 14}
										>
											Зарплата
										</TableCell>
									)}
									{!asUser && modules?.payrollCanWatchAllAccruals && (
										<TableCell
											style={{ textAlign: 'center', border: '1px solid black' }}
											colSpan={3}
										></TableCell>
									)}
								</TableRow>
								<TableRow>
									{headers.length > 0 &&
										headers.map((x, i) => (
											<TableCell
												style={{
													border: '1px solid black',
													fontSize: '10px',
													fontWeight: 'bold',
												}}
												colSpan={1}
												key={`thc-${i}`}
											>
												{x.name}
											</TableCell>
										))}
									{modules?.payrollCanWatchAllAccruals && !asUser && (
										<TableCell
											style={{
												border: '1px solid black',
												fontSize: '10px',
												fontWeight: 'bold',
											}}
											colSpan={2}
										>
											Действия
										</TableCell>
									)}
								</TableRow>
							</TableHead>
							<TableBody>
								{accruals.length > 0 &&
									accruals.map((accrual, i) => (
										<AccrualRow
											asUser={asUser}
											key={i}
											accrual={accrual}
											i={i}
											headers={headers}
											startEditing={startEditing}
											deleteAccrualHandler={deleteAccrualHandler}
											modules={modules}
										/>
									))}
								{modules?.payrollPayroller &&
									!modules?.payrollCanWatchAllAccruals &&
									totalCash && (
										<TableRow>
											{headers.length > 0 &&
												headers.map((head, k) => (
													<TableCell
														key={`trc-${k}`}
														style={{
															backgroundColor:
																head.prop === 'total' ||
																head.prop === 'totalO' ||
																head.prop === 'totalA'
																	? '#f3f3f4'
																	: null,
															fontSize: '10px',
															fontWeight: '500',
															textAlign: 'center',
															padding: '1px',
														}}
													>
														{totalCash[head.prop]
															? utils.parseAndFormat(totalCash[head.prop])
															: undefined}
													</TableCell>
												))}
										</TableRow>
									)}
								{((total &&
									!modules?.payrollPayroller &&
									!modules?.payrollCanWatchAllAccruals) ||
									asUser) && (
									<TableRow>
										<TableCell colSpan='3' style={{ textAlign: 'center' }}>
											{`Итого: ${utils.parseAndFormat(total) || null}`}
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					)}
				</div>
				<div>
					{accruals.length === 0 && !isLoading && (
						<span>Данные отстутствуют.</span>
					)}
				</div>
			</div>
		</>
	)
}

export default AccrualTable
