import React, { useEffect } from 'react'
import { useState } from 'react'
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import { connect, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { green, grey } from '@material-ui/core/colors'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const GreenCheckbox = withStyles({
	root: {
		color: grey[400],
		'&$checked': {
			color: green[600],
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />)

const Incoming = (props) => {
	const { modules } = useSelector((store) => store.session)
	const { subdivisions } = useSelector((store) => store.operationReport)

	const normalizeAmount = (val) => {
		if (!val) return ''
		let norm = val * 100
		return parseFloat(norm.toFixed())
	}
	const formatAmount = (input) => {
		if (!input) return ''
		return input / 100
	}

	// normalize
	// Преобразуем данные количества
	const formatQuantity = (input) => {
		if (!input) return ''
	}

	const normalizeQuantity = (val) => {
		if (!val) return ''
		return parseFloat(val.toFixed())
	}
	const checkRequired = (nameOfProduct) => {
		const isFinded = choosenProduct.find((p) => p.name === nameOfProduct)
		if (!isFinded) return false
		return true
	}
	const {
		counterparty,
		handleSubmit,
		onSubmit,
		manifest,
		cancelHandler,
		onchangeHandler,
		RenderCommentField,
		RenderSelectField,
		RenderSumField,
		RenderQuantityField,
		required,
		change,
	} = props
	const [arrOfTypesIncomming, setarrOfTypesIncomming] = useState([])
	const [arrOfProductsIncomming, setArrOfProductsIncomming] = useState([])
	const [disableSumField, setDisableSumField] = useState(false)
	const [choosenProduct, setChoosenProduct] = useState([])

	useEffect(() => {
		if (counterparty && counterparty === 'Товары') {
			setDisableSumField(true)
			setArrOfProductsIncomming(manifest.INCOMING_PRODUCTS)
		} else {
			setArrOfProductsIncomming([])
			setDisableSumField(false)
		}
	}, [counterparty, manifest.INCOMING_PRODUCTS])

	useEffect(() => {
		const totalSum = choosenProduct.reduce((sum, current) => {
			const currentSum = current.quantity * current.sum
			if (currentSum) return sum + currentSum
			else return sum
		}, 0)
		change('operationReportFormIncoming', 'sum', totalSum)
		change('operationReportFormIncoming', 'customFields', choosenProduct)
	}, [change, choosenProduct])

	const checkBoxHandleChange = (event, product) => {
		const isDone = event.target.checked

		if (isDone)
			setChoosenProduct((choosenProduct) => [
				...choosenProduct,
				{ name: product },
			])
		if (!isDone) {
			setChoosenProduct(choosenProduct.filter((item) => item.name !== product))
			change('operationReportFormIncoming', `${product}-quantity`, '')
			change('operationReportFormIncoming', `${product}-sum`, '')
		}
	}
	const quantityHandleChange = (event, product) => {
		const quantity = +event.target.value
		if (quantity)
			setChoosenProduct(
				choosenProduct.map((item) =>
					item.name === product ? { ...item, quantity } : item
				)
			)
	}
	const sumHandleChange = (event, product) => {
		const sum = normalizeAmount(+event.target.value)
		if (sum)
			setChoosenProduct(
				choosenProduct.map((item) =>
					item.name === product ? { ...item, sum } : item
				)
			)
	}

	const localHandlerInc = (e) => {
		if (e.target.value) {
			if (e.target.value === 'Внутренние перемещения') {
				if (subdivisions && subdivisions.length > 0) {
					setarrOfTypesIncomming(subdivisions)
				} else {
					setarrOfTypesIncomming([])
				}
				setArrOfProductsIncomming([])
				setDisableSumField(false)
			} else if (e.target.value === 'Основные') {
				setarrOfTypesIncomming(manifest.COUNTERPARTY_INCOMING_BASE_TYPES)
			}
		}
	}
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className='modal is-active'>
				<div className='modal-background'></div>
				<div className='modal-card'>
					<header className='modal-card-head'>
						<p className='modal-card-title'>{manifest.MODALE_TITLE}</p>
						<button
							className='delete'
							aria-label='close'
							onClick={cancelHandler}
						></button>
					</header>
					<section className='modal-card-body'>
						{/* <pre>
							<code>{selectType}</code>
						</pre> */}
						<div className='container'>
							<div className='columns is-centered'>
								<div className='column is-10'>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Операция</label>
												</div>
											</div>
											<div className='control control-custom'>
												<div className='select is-fullwidth'>
													<Field
														name={'operationType'}
														component={'select'}
														// onChange={onchangeHandler}
														onChange={(e) => {
															change(
																'operationReportFormIncoming',
																'operationType',
																e.target.value
															)
															onchangeHandler(e)
														}}
													>
														{manifest.OPERATION_TYPES.filter(
															(i) => modules[i.key]
														).map((type, i) => {
															return (
																<option key={i} data-key={type.key}>
																	{type.value}
																</option>
															)
														})}
													</Field>
												</div>
											</div>
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Группа Контрагента</label>
												</div>
											</div>
											<Field
												name={'counterpartyGroup'}
												component={RenderSelectField}
												validate={required}
												onChange={localHandlerInc}
											>
												<option value='' disabled>
													Выберите группу...
												</option>
												{manifest.COUNTERPARTY_INCOMING_GROUPS.map(
													(type, i) => {
														return <option key={i}>{type}</option>
													}
												)}
											</Field>
										</div>
									</div>
									{arrOfTypesIncomming.length > 0 && (
										<div className='level is-centered'>
											<div className='level-item'>
												<div className='field is-horizontal lable-custom'>
													<div className='field-label is-normal'>
														<label className='label'>Контрагент</label>
													</div>
												</div>
												<Field
													name={'counterparty'}
													component={RenderSelectField}
													validate={required}
												>
													<option value='' disabled>
														Выберите Контрагента...
													</option>
													{arrOfTypesIncomming.length > 0 &&
														arrOfTypesIncomming.map((type, i) => {
															return (
																<option value={type} key={i}>
																	{type}
																</option>
															)
														})}
												</Field>
											</div>
										</div>
									)}
									{arrOfProductsIncomming.length > 0 && (
										<table className='table is-bordered is-fullwidth'>
											<thead>
												<tr>
													<th style={{ width: '10%' }}></th>
													<th style={{ width: '40%' }}>Товар</th>
													<th style={{ width: '20%' }}>Кол.во</th>
													<th style={{ width: '20%' }}>Стоимость</th>
												</tr>
											</thead>
											<tbody>
												{arrOfProductsIncomming.map((product, i) => {
													return (
														<tr key={i}>
															<td>
																<div style={{ cursor: 'pointer' }}>
																	<GreenCheckbox
																		style={{ width: 28, height: 28 }}
																		icon={
																			<CheckBoxOutlineBlankIcon
																				style={{ fontSize: 20 }}
																			/>
																		}
																		checkedIcon={
																			<CheckBoxIcon style={{ fontSize: 20 }} />
																		}
																		onChange={(e) => {
																			checkBoxHandleChange(e, product)
																		}}
																	/>
																</div>
															</td>
															<td>{product}</td>
															<td>
																<div className='field-body'>
																	<div className='field is-narrow'>
																		<Field
																			props={{ type: 'boolean' }}
																			{...{
																				isDisabled: !checkRequired(product),
																			}}
																			name={`${product}-quantity`}
																			component={RenderQuantityField}
																			type={'number'}
																			parse={Number}
																			format={formatQuantity}
																			normalize={normalizeQuantity}
																			validate={
																				checkRequired(product)
																					? required
																					: false
																			}
																			onChange={(e) => {
																				quantityHandleChange(e, product)
																			}}
																		></Field>
																	</div>
																</div>
															</td>
															<td>
																<div className='field-body'>
																	<div className='field is-narrow'>
																		<Field
																			name={`${product}-sum`}
																			props={{ type: 'boolean' }}
																			{...{
																				customClass: true,
																				isDisabled: !checkRequired(product),
																			}}
																			component={RenderSumField}
																			type={'number'}
																			parse={Number}
																			format={formatAmount}
																			normalize={normalizeAmount}
																			validate={
																				checkRequired(product)
																					? required
																					: false
																			}
																			onChange={(e) => {
																				sumHandleChange(e, product)
																			}}
																		></Field>
																	</div>
																</div>
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									)}
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Сумма</label>
												</div>
											</div>
											{!disableSumField ? (
												<Field
													props={{ type: 'boolean' }}
													{...{
														isDisabled: false,
													}}
													name={'sum'}
													component={RenderSumField}
													type={'number'}
													parse={Number}
													format={formatAmount}
													normalize={normalizeAmount}
													validate={required}
												></Field>
											) : (
												<div className='control control-custom'>
													<Field
														className='input is-fullwidth'
														placeholder={'0,00'}
														name={'sum'}
														component={'input'}
														type={'number'}
														parse={Number}
														format={formatAmount}
														normalize={normalizeAmount}
														validate={required}
														disabled={disableSumField}
													></Field>
												</div>
											)}
										</div>
									</div>
									<div className='level is-centered'>
										<div className='level-item'>
											<div className='field is-horizontal lable-custom'>
												<div className='field-label is-normal'>
													<label className='label'>Комментарий</label>
												</div>
											</div>
											<Field
												type={'text'}
												name={'comment'}
												component={RenderCommentField}
												validate={required}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<footer className='modal-card-foot'>
						<button className='button is-success' type='submit'>
							{manifest.MODALE_SUBMITE}
						</button>
						<button className='button' onClick={cancelHandler}>
							{manifest.MODALE_CANCEL}
						</button>
					</footer>
				</div>
			</div>
		</form>
	)
}
const selector = formValueSelector('operationReportFormIncoming')
const mapStateToProps = (state) => ({
	counterparty: selector(state, 'counterparty'),
})
const mapDispatchToProps = {
	change,
}
export default reduxForm({
	// a unique name for the form
	form: 'operationReportFormIncoming',
	initialValues: {
		operationType: 'Приход',
		username: 'Peter',
		counterparty: '',
		comment: '',
	},
})(connect(mapStateToProps, mapDispatchToProps)(Incoming))
