import { createStore, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { reducer as form } from 'redux-form'
import { composeWithDevTools } from 'redux-devtools-extension'
import * as reducers from './entities'
import { rootSaga } from './entities'
import { apiService, logger } from './middlewares'

const sagaMiddleware = createSagaMiddleware()
let middlewaresArray = [apiService, sagaMiddleware]
if (process.env.NODE_ENV !== 'production')
	middlewaresArray = [...middlewaresArray, logger]
const configureStore = () => {
	const rootReducer = combineReducers(reducers, form)
	const store = createStore(
		rootReducer,
		composeWithDevTools(applyMiddleware(...middlewaresArray))
	)
	sagaMiddleware.run(rootSaga)
	return store
}
export default configureStore
