import * as types from './types'

export const fetchSession = () => ({
	type: types.FETCH_SESSION,
	meta: {
		async: true,
		blocking: true,
		path: '/system/session',
		method: 'GET',
	},
})
