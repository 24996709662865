import React from 'react'
import { useSelector } from 'react-redux'

const SelectFilter = ({ modules, arrayOfColumn, operation, dispatch }) => {
	const { searchFilter } = useSelector((store) => store.operationReport.table)
	return (
		<>
			{modules && (
				<div className='select is-fullwidth'>
					<select
						id='custom-select'
						value={searchFilter.operationType || ''}
						type='select'
						onChange={(e) => {
							dispatch(operation(e.target.value))
						}}
					>
						<option value=''>Все операции</option>
						{arrayOfColumn
							.filter((i) => modules && modules[i.key])
							.map((option) => (
								<option key={option.key} value={option.value}>
									{option.value}
								</option>
							))}
					</select>
				</div>
			)}
		</>
	)
}
export default SelectFilter
