import { combineReducers } from 'redux'
import * as types from './types'
import * as utils from './utils'
import { createReducer, calculatePages } from '../../utils'
import * as accrualConstants from './constants'

const searchProps = {
	offset: false,
	limit: 0,
	page: 1,
	pages: [],
	showedOn: 1,
	showedFor: 0,
	canBack: false,
	canFwd: true,
	predicate: 'dateAccrual',
	reverse: true,
	searchFilter: {
		employeeId: null,
		payrollerId: null,
		username: null,
		counterparty: null,
		startDate: null,
		endDate: null,
		subdivision: null,
	},
}
const columns = accrualConstants.columnsForAccrualTable

const accrualsReducer = createReducer([])({
	[types.FETCH_ACCRUAL_REPORT_COMPLETED]: (state, action) => {
		const { data } = action.payload
		if (data) {
			return data
		}
		return []
	},
	[types.NORMALIZE_DATA]: (state, action) => {
		const {
			accruals,
			modules,
			user,
			employees,
			employeWithCredit,
		} = action.payload
		const { asUser } = searchProps
		if (accruals) {
			if (
				!asUser &&
				(modules?.payrollCanWatchAllAccruals || modules?.payrollPayroller)
			) {
				return utils.dataFormatter(accruals, employees, employeWithCredit)
			} else {
				return utils.dataFormatter(accruals, employees, employeWithCredit, user)
			}
		}
		return []
	},
	[types.FETCH_ACCRUAL_REPORT_FAILED]: (state, action) => {
		return []
	},
})
const accrualReducer = createReducer({})({
	[types.GET_CURRENT_EDITING_ACCRUAL_COMPLETED]: (state, action) => {
		const { data } = action.payload
		if (data) {
			return data
		}
		return {}
	},
	[types.GET_CURRENT_EDITING_ACCRUAL_FAILED]: (state, action) => {
		return {}
	},
})
const filterReducer = createReducer(searchProps)({
	[types.ADD_SESSION]: (state, action) => {
		const { searchFilter } = state
		const { modules, people, dateRange, asUser } = action.payload
		if (!modules || !people) return state
		searchProps.modules = modules
		if (asUser !== undefined) searchProps.asUser = asUser
		if (!asUser) searchFilter.employeeId = null
		const { personId } = people
		const { id, payrollerId } = personId
		if (
			asUser ||
			(!modules.payrollCanWatchAllAccruals && !modules.payrollPayroller) ||
			(modules.payrollPayroller && !payrollerId)
		) {
			searchFilter.employeeId = id
			searchFilter.payrollerId = null
		}
		if (
			modules.payrollPayroller &&
			payrollerId &&
			!modules.payrollCanWatchAllAccruals &&
			!asUser
		) {
			searchFilter.employeeId = null
			searchFilter.payrollerId = payrollerId
		}
		if (dateRange && dateRange.length > 0) {
			searchFilter.startDate = dateRange[0]
			searchFilter.endDate = dateRange[1]
		} else {
			searchFilter.startDate = null
			searchFilter.endDate = null
		}
		return { ...state, searchFilter }
	},
	[types.FETCH_ACCRUAL_REPORT_COMPLETED]: (state, action) => {
		const { total, data } = action.payload
		const { page, modules } = state
		if (modules?.payrollCanWatchAllAccruals) {
			const { pages, showedOn, showedFor, canBack, canFwd } = calculatePages(
				total,
				20,
				page,
				data
			)

			return { ...state, pages, showedOn, showedFor, canBack, canFwd }
		}
		return state
	},
	[types.STEP_FORWARD]: (state, action) => {
		let { page, canFwd, offset } = state
		offset = true
		if (canFwd) page++
		return { ...state, page, offset }
	},
	[types.STEP_BACK]: (state, action) => {
		let { page, canBack, offset } = state
		offset = true

		if (canBack) page--
		return { ...state, page, offset }
	},
	[types.STEP_TO_CHOSEN]: (state, action) => {
		const { pageNumber } = action.payload
		let { offset } = state
		offset = true

		return { ...state, page: pageNumber, offset }
	},
	[types.FILTER_BY_DATE]: (state, action) => {
		const { dateRange } = action.payload
		const { searchFilter } = state
		let { offset, page } = state
		offset = false
		page = 1

		if (dateRange && dateRange.length > 0) {
			searchFilter.startDate = dateRange[0]
			searchFilter.endDate = dateRange[1]
		} else {
			searchFilter.startDate = null
			searchFilter.endDate = null
		}
		return { ...state, searchFilter, offset, page }
	},
	[types.FILTER_BY_EMPLOYEE]: (state, action) => {
		const { employeeId } = action.payload
		const { searchFilter } = state
		let { offset, page } = state
		offset = false
		page = 1
		if (employeeId) {
			searchFilter.employeeId = employeeId
		} else {
			searchFilter.employeeId = null
		}
		return { ...state, searchFilter, offset, page }
	},
})
const loadingReducer = createReducer(true)({
	[types.FETCH_ACCRUAL_REPORT]: () => true,
	[types.FETCH_ACCRUAL_REPORT_COMPLETED]: () => false,
	[types.FETCH_ACCRUAL_REPORT_FAILED]: () => false,
})
const tableHeadersReducer = createReducer([])({
	[types.FILTER_HEADERS]: (state, action) => {
		let headers = columns
		const { accruals, modules } = action.payload
		const { asUser } = searchProps
		if (accruals) {
			let ifOnlyOneCredit, ifCreditTotal
			ifOnlyOneCredit = accruals && accruals.find((a) => a.credit)
			if (ifOnlyOneCredit === undefined)
				headers = headers.filter((c) => c.name !== 'Кредит/Возврат')
			// if admin or payroller
			if (
				!asUser &&
				(modules?.payrollCanWatchAllAccruals || modules?.payrollPayroller)
			) {
				return headers.filter((c) => c.name !== 'Долг перед компанией')
				// else user its all we need
			} else {
				headers = headers.filter((c) => c.name !== 'Выдано' && c.name !== 'ФИО')
				ifCreditTotal = accruals && accruals.find((a) => a.creditTotal)
				if (ifCreditTotal === undefined) {
					return headers.filter((c) => c.name !== 'Долг перед компанией')
				}
			}
		}
		return headers
	},
})

export default combineReducers({
	accruals: accrualsReducer,
	isLoading: loadingReducer,
	filter: filterReducer,
	accrual: accrualReducer,
	tableHeaders: tableHeadersReducer,
})
