import React from 'react'

const RenderSumField = ({
	input,
	type,
	customClass,
	isDisabled,
	meta: { touched, error, warning },
}) => (
	<div className={customClass ? 'control' : 'control  control-custom'}>
		<input
			{...input}
			type={type}
			className='input is-fullwidth'
			placeholder={'0,00'}
			disabled={customClass && isDisabled ? true : false}
		/>
		{touched &&
			((error && (
				<article className='message is-danger is-small'>
					<div className='message-body'>{error}</div>
				</article>
			)) ||
				(warning && (
					<article className='message is-danger is-small'>
						<div className='message-body'>{warning}</div>
					</article>
				)))}
	</div>
)

export default RenderSumField
