import * as types from './types'
import * as utils from './utils'

export const addSessionToOperationReportTableProps = (modules, people) => ({
	type: types.ADD_SESSION,
	payload: {
		modules,
		people,
	},
})
export const fetchAmmount = (props) => ({
	type: types.FETCH_AMMOUNT,
	meta: {
		async: true,
		blocking: true,
		path: utils.calculateUrlForAmmount(props),
		method: 'GET',
	},
})
export const fetchReport = (tableProps) => ({
	type: types.FETCH_REPORT,
	meta: {
		async: true,
		blocking: true,
		path: `/operations${utils.calculateUrl(tableProps)}`,
		method: 'GET',
	},
})
export const fetchReportXLS = (tableProps, reportType) => ({
	/**
	 * @param {tableProps} tableProps report query props and filters
	 * @param {REPORT_TYPE} reportForm XLS report form
	 */
	type: types.FETCH_REPORT_XLS,
	meta: {
		async: true,
		blocking: true,
		path: `/operations/getxlsx${utils.calculateReportUrl(
			tableProps,
			reportType
		)}`,
		method: 'GET',
		body: tableProps,
	},
})
export const fetchEmployees = () => ({
	type: types.FETCH_EMPLOYEES,
	meta: {
		async: true,
		blocking: true,
		path: '/system/employees',
		method: 'GET',
	},
})
export const fetchSubdivisions = () => ({
	type: types.FETCH_SUBDIVISIONS,
	meta: {
		async: true,
		blocking: true,
		path: '/system/subdivisions',
		method: 'GET',
	},
})
export const fetchCreditEmployees = () => ({
	type: types.FETCH_CREDIT,
	meta: {
		async: true,
		blocking: true,
		path: '/credit',
		method: 'GET',
	},
})
export const postCreditEmployee = (formProps) => ({
	type: types.POST_REPORT,
	meta: {
		async: true,
		blocking: true,
		path: '/credit',
		method: 'POST',
		body: formProps,
	},
})
export const deleteCreditEmployee = (employeeId) => ({
	type: types.DELETE_CREDIT,
	meta: {
		async: true,
		blocking: true,
		path: `/credit/${employeeId}`,
		method: 'DELETE',
	},
})
export const postReport = (formProps) => ({
	type: types.POST_REPORT,
	meta: {
		async: true,
		blocking: true,
		path: '/operations',
		method: 'POST',
		body: formProps,
	},
})
export const putDelete = (body) => ({
	type: types.PUT_DELETE,
	meta: {
		async: true,
		blocking: true,
		path: `/operations/delete`,
		method: 'POST',
		body: body,
	},
})
export const deleteReport = (reportId) => ({
	type: types.DELETE_REPORT,
	meta: {
		async: true,
		blocking: true,
		path: `/operations/${reportId}`,
		method: 'DELETE',
	},
})
export const postAccrualEmployee = (formProps) => ({
	type: types.POST_ACCRUAL,
	meta: {
		async: true,
		blocking: true,
		path: '/accruals',
		method: 'POST',
		body: formProps,
	},
})
export const fetchAccrualsEmployee = (employeeId) => ({
	type: types.FETCH_ACCRUALS,
	meta: {
		async: true,
		blocking: true,
		path: `/accruals/getAllByEmloyeeID/${employeeId}`,
		method: 'GET',
	},
})
export const getOneAccrual = (id, accruals) => ({
	type: types.GET_ONE_ACCRUAL,
	payload: { id, accruals },
})
export const putDoneAccrualEmployee = (id) => ({
	type: types.PUT_DONE_ACCRUAL,
	meta: {
		async: true,
		blocking: true,
		path: `/accruals/done/${id}`,
		method: 'PUT',
	},
})

export const showDetailsOperation = (info) => ({
	type: types.SHOW_DETAILS_OPERATION,
	payload: info,
})
export const filterBySubdivision = (subdivision) => ({
	type: types.FILTER_BY_SUBDIVISION,
	payload: { subdivision },
})

export const stepForward = () => ({
	type: types.STEP_FORWARD,
})

export const stepBack = () => ({
	type: types.STEP_BACK,
})

export const stepToChosen = (pageNumber) => ({
	type: types.STEP_TO_CHOSEN,
	payload: { pageNumber },
})
export const filterByDate = (dateRange) => ({
	type: types.FILTER_BY_DATE,
	payload: { dateRange },
})
export const filterByOperation = (operation) => ({
	type: types.FILTER_BY_OPERATION,
	payload: { operation },
})
export const filterByOperationState = (operationState) => ({
	type: types.FILTER_BY_OPERATION_STATE,
	payload: { operationState },
})

export const filterByAll = (criteria) => ({
	type: types.FILTER_BY_ALL,
	payload: { criteria },
})

export const selectType = (type) => ({
	type: types.SELECT_TYPE,
	payload: { type },
})
