import { combineReducers } from 'redux'

import * as types from './types'
import { createReducer } from '../../utils'

/* State shape
{
    details: product,
    list: [ product ],
}
*/

const showModalReducer = createReducer(false)({
	[types.SHOW_MODAL]: (state, action) => action.payload,
})
const categoryModalReducer = createReducer('')({
	[types.SET_MODAL_CATEGORY]: (state, action) => action.payload,
})

export default combineReducers({
	show: showModalReducer,
	category: categoryModalReducer,
})
