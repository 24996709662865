import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { reportOperations } from 'state/entities/operationReport'

const TotalFilter = () => {
	let [criteria, setCriteria] = useState('')
	const dispatch = useDispatch()
	const inputHandler = (e) => {
		if (e) e.preventDefault()
		dispatch(reportOperations.filterByAll(criteria))
	}
	const inputClear = (e) => {
		if (e) e.preventDefault()
		setCriteria(() => (criteria = ''))
		dispatch(reportOperations.filterByAll(criteria))
	}
	return (
		<article className='panel is-success'>
			<form onSubmit={inputHandler}>
				<div className='panel-block'>
					<p
						className='control has-icons-left has-icons-right'
						style={{ width: '92%' }}
					>
						<input
							className='input is-success'
							type='text'
							placeholder='Поиск'
							value={criteria}
							onChange={(e) => setCriteria(e.target.value)}
						/>
						<span className='icon is-left'>
							<i className='fas fa-search' aria-hidden='true'></i>
						</span>
					</p>
					{criteria && (
						<div
							style={{ width: '8%', cursor: 'pointer', paddingLeft: '10px' }}
							className='icon is-right'
							onClick={inputClear}
						>
							<i className='fas fa-times' aria-hidden='true'></i>
						</div>
					)}
				</div>
			</form>
		</article>
	)
}
export default TotalFilter
