import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useTable, useFilters, useGlobalFilter } from 'react-table'

import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { reportOperations } from 'state/entities/operationReport'
import { accrualOperations } from 'state/entities/accrualReport'
import { modalOperations } from 'state/entities/modal'
import { RowDetails, SelectFilter } from '../table'
import { TotalFilter } from '../operationReport'

function Table({
	operations,
	count,
	tableProps,
	dispatch,
	modules,
	Modal,
	manifest,
}) {
	const isModal = useSelector((store) => store.modal.show)
	const modalCategory = useSelector((store) => store.modal.category)
	const { user } = useSelector((store) => store.session)

	const tableManifest = {
		MODALE_TITLE: 'Детали операции',
		MODALE_SUBMITE: 'ОК',
		MODALE_CANCEL: 'Закрыть',
	}

	const rowInfo = (rowobject) => {
		dispatch(reportOperations.showDetailsOperation(rowobject))
		dispatch(modalOperations.show(true))
		dispatch(modalOperations.setCategory('rowInfo'))
	}

	const data = React.useMemo(() => operations, [operations])
	const columns = React.useMemo(
		() => [
			{
				Header: 'Дата',
				accessor: 'createdAt',
				maxWidth: 200,
				Filter: () => (
					<DateRangePicker
						value={[
							tableProps.searchFilter.startDate,
							tableProps.searchFilter.endDate,
						]}
						onChange={(value) => {
							dispatch(reportOperations.filterByDate(value))
						}}
					/>
				),
				filter: 'text',
			},
			{
				Header: 'Пользователь',
				accessor: 'username',
				maxWidth: 450,
			},
			{
				Header: 'Операция',
				accessor: 'operationType',
				maxWidth: 400,
				Filter: () => (
					<SelectFilter
						modules={modules}
						arrayOfColumn={manifest.OPERATION_TYPES}
						operation={reportOperations.filterByOperation}
						dispatch={dispatch}
					/>
				),
				filter: 'equals',
			},
			{
				Header: 'Контрагент',
				accessor: 'counterparty',
				maxWidth: 500,
			},
			{
				Header: 'Сумма',
				accessor: 'sum',
				maxWidth: 150,
			},
			{
				Header: 'Комментарий',
				accessor: 'comment',
				Cell: ({ cell: { value }, row: { original } }) => (
					<>
						{modules?.accountingCanRemoveRow ? (
							<>
								{original?.isDelete ? (
									<div className='commentBox'>
										<div className='commentValue'>{value}</div>
										<div className='commentIcon'>
											<div
												className='icon-box'
												onClick={(e) => {
													e.stopPropagation()
													window.confirm(
														'Данное действие удалит запись без возможности востановления! Хотите продолжить?'
													) &&
														dispatch(reportOperations.deleteReport(original.id))
												}}
											>
												<i className='fa fa-trash'></i>
											</div>
										</div>
									</div>
								) : (
									<div className='commentBox'>
										<div className='commentValue'>{value}</div>
										<div className='commentIcon'>
											<div
												className='icon-box'
												onClick={async (e) => {
													e.stopPropagation()
													if (original?.operationType === 'Начисление') {
														await dispatch(
															accrualOperations.deleteAccrualByOperationId(
																original.id
															)
														)
													}
													await dispatch(
														reportOperations.putDelete({
															id: original.id,
															userId: user?.personId?.id,
															modifyrerName: user?.fullname
																? user?.fullname
																: `${user.personId.lastName} ${user.personId.firstName} ${user.personId.patronymic}`,
														})
													)
												}}
											>
												<i className='fa fa-trash'></i>
											</div>
										</div>
									</div>
								)}
							</>
						) : (
							value
						)}
					</>
				),
			},
		],
		[
			dispatch,
			manifest.OPERATION_TYPES,
			modules,
			tableProps.searchFilter.endDate,
			tableProps.searchFilter.startDate,
			user,
		]
	)
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
		},

		useFilters,
		useGlobalFilter
	)

	return (
		<>
			<hr />
			<div className='columns is-vcentered'>
				<div className='column is-4'>
					<TotalFilter />
				</div>
				<div className='column is-4'>
					<div className='level is-centered'>
						<p className='level-item has-text-centered secondary-messages'>
							показаны записи с {tableProps.showedOn} по {tableProps.showedFor}{' '}
							из {count}
						</p>
					</div>
				</div>
			</div>
			<nav
				className='pagination is-centered'
				role='navigation'
				aria-label='pagination'
			>
				<button
					className='button pagination-previous is-light is-info'
					disabled={!tableProps.canBack}
					onClick={() => dispatch(reportOperations.stepBack())}
				>
					Назад
				</button>
				<button
					className='button pagination-next is-light is-info'
					disabled={!tableProps.canFwd}
					onClick={() => dispatch(reportOperations.stepForward())}
				>
					Вперед
				</button>
				<ul className='pagination-list'>
					{tableProps.pages.map((p, i) => {
						return (
							<li key={i} value={p.num}>
								<button
									className={
										p.num === tableProps.page
											? 'button is-info'
											: 'button is-info is-light '
									}
									onClick={() => dispatch(reportOperations.stepToChosen(p.num))}
								>
									{p.num}
								</button>
							</li>
						)
					})}
				</ul>
			</nav>
			<div style={{ overflow: 'auto' }}>
				<table
					{...getTableProps()}
					className='table is-striped is-hoverable is-fullwidth is-bordered panel is-primary'
				>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
										<div>{column.filter ? column.render('Filter') : null}</div>
									</th>
								))}
							</tr>
						))}
						{rows.length === 0 && (
							<tr>
								<th colSpan='6' align='center'>
									<span>Данные отстутствуют.</span>
								</th>
							</tr>
						)}
					</thead>
					{rows.length > 0 && (
						<tbody {...getTableBodyProps()}>
							{rows.map((row, i) => {
								prepareRow(row)
								return (
									<tr
										{...row.getRowProps({
											style: {
												cursor: 'pointer',
												backgroundColor:
													row.original.operationType === 'Приход'
														? '#BDF6BD'
														: row.original.operationType === 'Расход'
														? '#FEC3C7'
														: '',
												opacity: row?.original?.isDelete ? '0.3' : '1',
											},
											onClick: () => rowInfo(row),
										})}
									>
										{row.cells.map((cell) => {
											return (
												<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
											)
										})}
									</tr>
								)
							})}
						</tbody>
					)}
				</table>
			</div>
			{isModal && modalCategory && modalCategory === 'rowInfo' && (
				<Modal ModalTemplate={RowDetails} manifest={tableManifest} />
			)}
		</>
	)
}
const { object, func, array, number } = PropTypes

Table.propTypes = {
	operations: array.isRequired,
	count: number,
	tableProps: object.isRequired,
	dispatch: func.isRequired,
	modules: object,
}

export default Table
