import React from 'react'

const RenderSelectField = ({
	input,
	type,
	customClass,
	meta: { touched, error, warning },
	children,
}) => {
	return (
		<div className={customClass ? 'control' : 'control  control-custom'}>
			<div className='select' style={{ maxWidth: 250 }}>
				<select {...input} type={type} onBlur={() => {}}>
					{children}
				</select>
			</div>
			{touched &&
				((error && (
					<article className='message is-danger is-small'>
						<div className='message-body'>{error}</div>
					</article>
				)) ||
					(warning && (
						<article className='message is-danger is-small'>
							<div className='message-body'>{warning}</div>
						</article>
					)))}
		</div>
	)
}
export default RenderSelectField
