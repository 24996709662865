import { takeEvery, put, select } from 'redux-saga/effects'
import { showNotification } from '../notification/operations'
import { tableProps } from './selectors'
import {
	fetchReport,
	fetchAmmount,
	fetchEmployees,
	fetchSubdivisions,
	fetchCreditEmployees,
} from './operations'
import { modules } from '../auth/selectors'

import * as types from './types'

export function* operationWatcher() {
	try {
		yield takeEvery(types.FETCH_AMMOUNT_COMPLETED, fetchWorker)
		yield takeEvery(types.FETCH_AMMOUNT_COMPLETED, notificationWorker, {
			show: true,
			status: 'success',
			message: 'Данные успешно загружены!',
		})
		yield takeEvery(types.FETCH_AMMOUNT_FAILED, notificationWorker, {
			show: true,
			status: 'error',
			message: 'Ошибка получения данных с сервера!',
		})
		yield takeEvery(types.POST_REPORT_COMPLETED, notificationWorker, {
			show: true,
			status: 'success',
			message: 'Данные успешно отправлены!',
		})
		yield takeEvery(types.POST_REPORT_FAILED, notificationWorker, {
			show: true,
			status: 'error',
			message: 'Ошибка отправки данных на сервер!',
		})
		yield takeEvery(types.FETCH_ACCRUALS_FAILED, notificationWorker, {
			show: true,
			status: 'error',
			message: 'Нет начислений для выбранного сотрудника!',
		})
		yield takeEvery(types.FETCH_REPORT_FAILED, notificationWorker, {
			show: true,
			status: 'error',
			message: 'Нет операций по данному запросу!',
		})
		yield takeEvery(types.POST_REPORT_COMPLETED, postWorker)
		yield takeEvery(types.DELETE_REPORT_COMPLETED, postWorker)
		yield takeEvery(types.PUT_DELETE_COMPLETED, postWorker)
		yield takeEvery(types.DELETE_CREDIT_COMPLETED, fetchWorker)
		yield takeEvery(types.STEP_FORWARD, tableTriggerWorker)
		yield takeEvery(types.STEP_BACK, tableTriggerWorker)
		yield takeEvery(types.STEP_TO_CHOSEN, tableTriggerWorker)
		yield takeEvery(types.FILTER_BY_DATE, tableTriggerWorker)
		yield takeEvery(types.FILTER_BY_ALL, tableTriggerWorker)
		yield takeEvery(types.FILTER_BY_SUBDIVISION, tableTriggerWorker)
		yield takeEvery(types.FILTER_BY_OPERATION, tableTriggerWorker)
		yield takeEvery(types.FILTER_BY_OPERATION_STATE, tableTriggerWorker)
	} catch (error) {
		console.log(error)
	}
}
function* notificationWorker(meta) {
	try {
		yield put(showNotification(meta))
	} catch (error) {
		console.log(error)
	}
}

function* tableTriggerWorker() {
	try {
		const props = yield select(tableProps)
		yield put(fetchReport(props))
	} catch (error) {
		console.log(error)
	}
}
function* fetchWorker() {
	try {
		const props = yield select(tableProps)
		yield put(fetchReport(props))
		yield put(fetchEmployees())
		yield put(fetchSubdivisions())
		yield put(fetchCreditEmployees())
	} catch (error) {
		console.log(error)
	}
}
function* postWorker() {
	try {
		const props = yield select(modules)
		yield put(fetchAmmount(props))
	} catch (error) {
		console.log(error)
	}
}
