import React from 'react'

const RenderCommentField = ({
	input,
	type,
	meta: { touched, error, warning },
}) => (
	<div className='control control-custom'>
		<textarea
			{...input}
			type={type}
			className='textarea is-fullwidth'
			placeholder={'Комментарий'}
		/>
		{touched &&
			((error && (
				<article className='message is-danger is-small'>
					<div className='message-body'>{error}</div>
				</article>
			)) ||
				(warning && (
					<article className='message is-danger is-small'>
						<div className='message-body'>{warning}</div>
					</article>
				)))}
	</div>
)
export default RenderCommentField
