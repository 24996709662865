import * as types from './types'
import * as utils from './utils'

export const addSessionToAccrualSearchProps = (
	modules,
	people,
	dateRange,
	asUser
) => ({
	type: types.ADD_SESSION,
	payload: {
		modules,
		people,
		dateRange,
		asUser,
	},
})
export const getCurrentEditingAccrual = (accrualId) => ({
	type: types.GET_CURRENT_EDITING_ACCRUAL,
	meta: {
		async: true,
		blocking: true,
		path: `/accruals/GetOneForEditing/${accrualId}`,
		method: 'GET',
	},
})
export const fetchAccrualReport = (searchProps) => ({
	type: types.FETCH_ACCRUAL_REPORT,
	meta: {
		async: true,
		blocking: true,
		path: `/accruals/${utils.calculateUrl(searchProps)}`,
		method: 'GET',
	},
})
export const putAccrual = (id, accrual) => ({
	type: types.PUT_ACCRUAL,
	meta: {
		async: true,
		blocking: true,
		path: `/accruals/${id}`,
		method: 'PUT',
		body: accrual,
	},
})
export const putDoneForPayroller = (id, isDone) => ({
	type: types.PUT_DONE_FOR_PAYROLLER_ACCRUAL,
	meta: {
		async: true,
		blocking: true,
		path: `/accruals/putDoneForPayroller/?id=${id}&isDoneForPayroller=${isDone}`,
		method: 'PUT',
	},
})
export const deleteAccrual = (id) => ({
	type: types.DELETE_ACCRUAL,
	meta: {
		async: true,
		blocking: true,
		path: `/accruals/${id}`,
		method: 'DELETE',
	},
})
export const deleteAccrualByOperationId = (id) => ({
	type: types.DELETE_ACCRUAL_BY_OPERATION_ID,
	meta: {
		async: true,
		blocking: true,
		path: `/accruals/deleteByOperationId/${id}`,
		method: 'DELETE',
	},
})
export const stepForward = () => ({
	type: types.STEP_FORWARD,
})

export const stepBack = () => ({
	type: types.STEP_BACK,
})

export const stepToChosen = (pageNumber) => ({
	type: types.STEP_TO_CHOSEN,
	payload: { pageNumber },
})

export const filterByEmployee = (employeeId = null) => ({
	type: types.FILTER_BY_EMPLOYEE,
	payload: { employeeId },
})
export const filterByDate = (dateRange) => ({
	type: types.FILTER_BY_DATE,
	payload: { dateRange },
})
export const filterHeadres = (accruals, modules, user) => ({
	type: types.FILTER_HEADERS,
	payload: { accruals, modules, user },
})

export const normalizeData = (
	accruals,
	modules,
	user,
	employees,
	employeWithCredit
) => ({
	type: types.NORMALIZE_DATA,
	payload: { accruals, modules, user, employees, employeWithCredit },
})
