import React from 'react'
import { render } from 'react-dom'
import './index.sass'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import configureStore from './state/store'

const reduxStore = configureStore()

const publicUrl = new URL(process.env.PUBLIC_URL)

const baseName = publicUrl.pathname

const app = (
	<ReduxProvider store={reduxStore}>
		<Router basename={baseName}>
			<App />
		</Router>
	</ReduxProvider>
)

render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
