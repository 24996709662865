export const ADD_SESSION = 'operationReport/ADD_SESSION'

export const FETCH_AMMOUNT = 'operationReport/FETCH_AMMOUNT'
export const FETCH_AMMOUNT_COMPLETED = 'operationReport/FETCH_AMMOUNT_COMPLETED'
export const FETCH_AMMOUNT_FAILED = 'operationReport/FETCH_AMMOUNT_FAILED'

export const FETCH_REPORT = 'operationReport/FETCH_REPORT'
export const FETCH_REPORT_COMPLETED = 'operationReport/FETCH_REPORT_COMPLETED'
export const FETCH_REPORT_FAILED = 'operationReport/FETCH_REPORT_FAILED'

export const FETCH_REPORT_XLS = 'operationReport/FETCH_REPORT_XLS'
export const FETCH_REPORT_XLS_COMPLETED =
	'operationReport/FETCH_REPORT_XLS_COMPLETED'
export const FETCH_REPORT_XLS_FAILED = 'operationReport/FETCH_REPORT_XLS_FAILED'

export const FETCH_EMPLOYEES = 'operationReport/FETCH_EMPLOYEES'
export const FETCH_EMPLOYEES_COMPLETED =
	'operationReport/FETCH_EMPLOYEES_COMPLETED'
export const FETCH_EMPLOYEES_FAILED = 'operationReport/FETCH_EMPLOYEES_FAILED'

export const FETCH_SUBDIVISIONS = 'operationReport/FETCH_SUBDIVISIONS'
export const FETCH_SUBDIVISIONS_COMPLETED =
	'operationReport/FETCH_SUBDIVISIONS_COMPLETED'
export const FETCH_SUBDIVISIONS_FAILED =
	'operationReport/FETCH_SUBDIVISIONS_FAILED'

export const POST_REPORT = 'operationReport/POST_REPORT'
export const POST_REPORT_COMPLETED = 'operationReport/POST_REPORT_COMPLETED'
export const POST_REPORT_FAILED = 'operationReport/POST_REPORT_FAILED'

export const DELETE_REPORT = 'operationReport/DELETE_REPORT'
export const DELETE_REPORT_COMPLETED = 'operationReport/DELETE_REPORT_COMPLETED'
export const DELETE_REPORT_FAILED = 'operationReport/DELETE_REPORT_FAILED'

export const PUT_DELETE = 'operationReport/PUT_DELETE'
export const PUT_DELETE_COMPLETED = 'operationReport/PUT_DELETE_COMPLETED'
export const PUT_DELETE_FAILED = 'operationReport/PUT_DELETE_FAILED'

export const FETCH_ACCRUALS = 'operationReport/FETCH_ACCRUALS'
export const FETCH_ACCRUALS_COMPLETED =
	'operationReport/FETCH_ACCRUALS_COMPLETED'
export const FETCH_ACCRUALS_FAILED = 'operationReport/FETCH_ACCRUALS_FAILED'

export const POST_ACCRUAL = 'operationReport/POST_ACCRUAL'
export const POST_ACCRUAL_COMPLETED = 'operationReport/POST_ACCRUAL_COMPLETED'
export const POST_ACCRUAL_FAILED = 'operationReport/POST_ACCRUAL_FAILED'
export const PUT_DONE_ACCRUAL = 'operationReport/PUT_DONE_ACCRUAL'
export const PUT_DONE_ACCRUAL_COMPLETED =
	'operationReport/PUT_DONE_ACCRUAL_COMPLETED'
export const PUT_DONE_ACCRUAL_FAILED = 'operationReport/PUT_DONE_ACCRUAL_FAILED'
export const DELETE_ACCRUAL = 'operationReport/DELETE_ACCRUAL'
export const DELETE_ACCRUAL_COMPLETED =
	'operationReport/DELETE_ACCRUAL_COMPLETED'
export const DELETE_ACCRUAL_FAILED = 'operationReport/DELETE_ACCRUAL_FAILED'

export const FETCH_CREDIT = 'operationReport/FETCH_CREDIT'
export const FETCH_CREDIT_COMPLETED = 'operationReport/FETCH_CREDIT_COMPLETED'
export const FETCH_CREDIT_FAILED = 'operationReport/FETCH_CREDIT_FAILED'
export const RESET_CREDIT = 'operationReport/RESET_CREDIT'
export const DELETE_CREDIT = 'operationReport/DELETE_CREDIT'
export const DELETE_CREDIT_COMPLETED = 'operationReport/DELETE_CREDIT_COMPLETED'
export const DELETE_CREDIT_FAILED = 'operationReport/DELETE_CREDIT_FAILED'

export const SHOW_DETAILS_OPERATION = 'operationReport/SHOW_DETAILS_OPERATION'

export const STEP_FORWARD = 'operationReport/STEP_FORWARD'
export const STEP_BACK = 'operationReport/STEP_BACK'
export const STEP_TO_CHOSEN = 'operationReport/STEP_TO_CHOSEN'

export const FILTER_BY_DATE = 'operationReport/FILTER_BY_DATE'
export const FILTER_BY_ALL = 'operationReport/FILTER_BY_ALL'
export const FILTER_BY_SUBDIVISION = 'operationReport/FILTER_BY_SUBDIVISION'
export const FILTER_BY_OPERATION = 'operationReport/FILTER_BY_OPERATION'
export const FILTER_BY_OPERATION_STATE =
	'operationReport/FILTER_BY_OPERATION_STATE'

export const SELECT_TYPE = 'operationReport/SELECT_TYPE'

export const GET_ONE_ACCRUAL = 'operationReport/GET_ONE_ACCRUAL'
